import { Box, Button, Container, Dialog, Grid2, Typography } from '@mui/material';

type Props = {
  isOpen: boolean;
  text: string;
  confirmAction: () => void;
  cancelAction: () => void;
};

const ConfirmationModal = ({ isOpen, text, confirmAction, cancelAction }: Props) => {
  return (
    <Dialog open={isOpen}>
      <Container>
        <Box padding={5}>
          <Typography
            variant='h4'
            gutterBottom
          >
            {text}
          </Typography>
          <Grid2
            container
            spacing={2}
          >
            <Grid2 size={6}>
              <Button
                type='submit'
                onClick={confirmAction}
                variant='contained'
                color='primary'
                sx={{ minWidth: { xs: 110, md: 200 } }}
              >
                Confirm
              </Button>
            </Grid2>
            <Grid2 size={6}>
              <Button
                type='submit'
                onClick={cancelAction}
                variant='contained'
                color='primary'
                sx={{ minWidth: { xs: 110, md: 200 } }}
              >
                Cancel
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Dialog>
  );
};

export { ConfirmationModal };
