import { Route, Routes } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { About, Home, NotFound } from '@/pages';
import { AdminHome } from '@/pages/admin';
import { AdminEditBrand, AdminListBrands } from '@/pages/admin/brands';
import { AdminEditDistributor, AdminListDistributors } from '@/pages/admin/distributors';
import { AdminEditRetailer, AdminListRetailers } from '@/pages/admin/retailers';
import { AdminListSubscribers } from '@/pages/admin/subscribers';

const AppRouter = () => {
  const { user } = useAuth();

  const publicRoutes = [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/admin',
      element: <AdminHome />
    }
  ];

  const adminRoutes = [
    {
      path: '/admin/brands',
      element: <AdminListBrands />
    },
    {
      path: '/admin/brands/create',
      element: <AdminEditBrand />
    },
    {
      path: '/admin/brands/:brandId',
      element: <AdminEditBrand />
    },
    {
      path: '/admin/retailers',
      element: <AdminListRetailers />
    },
    {
      path: '/admin/retailers/create',
      element: <AdminEditRetailer />
    },
    {
      path: '/admin/retailers/:retailerId',
      element: <AdminEditRetailer />
    },
    {
      path: '/admin/distributors',
      element: <AdminListDistributors />
    },
    {
      path: '/admin/distributors/create',
      element: <AdminEditDistributor />
    },
    {
      path: '/admin/distributors/:retailerId',
      element: <AdminEditDistributor />
    },
    {
      path: '/admin/subscribers',
      element: <AdminListSubscribers />
    }
  ];

  const roleSpecificRoutes = user?.role === 'admin' ? adminRoutes : [];

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          {...route}
        />
      ))}
      {roleSpecificRoutes.map((route) => (
        <Route
          key={route.path}
          {...route}
        />
      ))}
      <Route
        key='*'
        path='*'
        element={<NotFound />}
      />
    </Routes>
  );
};

export { AppRouter };
