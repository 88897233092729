import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { countries, socialNetworks } from '@/globals/constants';
import { Brand, Country, Distributor, Location, Retailer } from '@/globals/types';
import { useBrands, useCloudflare, useDistributors, useRetailers } from '@/hooks';
import { retailerSchema } from '@/pages/admin/retailers/schemas';
import {
  DeleteImageButton,
  EditBrandModal,
  EditDistributorModal,
  EditImageButton,
  Error,
  Field,
  FieldAutocomplete,
  Form,
  FormActions,
  Loading,
  LocationFields,
  ViewImageButton
} from '.';

type RetailerFormData = Omit<
  Retailer,
  '_id' | 'otherImages' | 'scraperStatus' | 'admins' | 'createdAt' | 'updatedAt'
> & {
  brands: Pick<Brand, '_id' | 'name' | 'slug' | 'retailers'>[];
  distributors: Pick<Distributor, '_id' | 'name' | 'slug' | 'retailers'>[];
};

type Props = {
  retailerId?: string;
  onSuccess?: (retailer: Retailer) => void;
  onCancel?: () => void;
  isBrandOnlineStore?: boolean;
  isDistributorOnlineStore?: boolean;
};

const RetailerForm = ({ retailerId, onSuccess, onCancel, isBrandOnlineStore, isDistributorOnlineStore }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createRetailer, editRetailer, fetchRetailer, fetchRetailerBySlug } = useRetailers();
  const { editBrand, fetchAllBrands } = useBrands();
  const { editDistributor, fetchAllDistributors } = useDistributors();
  const { uploadImage, deleteImage } = useCloudflare();

  const [selectedBrands, setSelectedBrands] = useState<(Brand & { _id: string })[]>([]);
  const [isEditBrandModalOpen, setIsEditBrandModalOpen] = useState(false);
  const [selectedDistributors, setSelectedDistributors] = useState<Distributor[]>([]);
  const [isEditDistributorModalOpen, setIsEditDistributorModalOpen] = useState(false);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [logo, setLogo] = useState<File | null>();
  const [heroImage, setHeroImage] = useState<File | null>();
  const [locations, setLocations] = useState<Location[]>([{ address: '', state: '', city: '', zipCode: '' }]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm<RetailerFormData>({ resolver: zodResolver(retailerSchema) });

  const {
    data: retailer,
    isLoading: isFetchingRetailer,
    error: fetchRetailerError
  } = useQuery({
    queryKey: ['fetchRetailer', retailerId],
    enabled: !!retailerId,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const retailer = await fetchRetailer(retailerId as string);
      if (retailer.locations) setLocations(retailer.locations);
      return retailer;
    }
  });

  const {
    data: brands,
    isLoading: isFetchingAllBrands,
    error: fetchAllBrandsError
  } = useQuery({
    queryKey: ['fetchAllBrands'],
    enabled: !retailerId || !!retailer,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allBrands = (await fetchAllBrands()).brands.map(({ _id, name, slug, retailers }) => ({
        _id,
        name,
        slug,
        retailers
      }));
      setSelectedBrands((prev) => {
        return allBrands.filter(({ _id, retailers }) =>
          prev.length
            ? prev.some(({ _id: selectedId }) => _id === selectedId)
            : (retailers as string[]).includes(retailerId ?? '')
        );
      });
      return allBrands.concat({ _id: '', name: 'Other', slug: '', retailers: [] });
    }
  });

  const {
    data: distributors,
    isLoading: isFetchingAllDistributors,
    error: fetchAllDistributorsError
  } = useQuery({
    queryKey: ['fetchAllDistributors'],
    enabled: !retailerId || !!retailer,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allDistributors = (await fetchAllDistributors()).distributors.map(({ _id, name, slug, retailers }) => ({
        _id,
        name,
        slug,
        retailers
      }));
      setSelectedDistributors((prev) =>
        allDistributors.filter(({ _id, retailers }) =>
          prev.length
            ? prev.some(({ _id: prevId }) => _id === prevId)
            : (retailers as string[]).includes(String(retailerId))
        )
      );
      return allDistributors.concat({ _id: '', name: 'Other', slug: '', retailers: [] });
    }
  });

  const {
    isPending: isMutatingDistributor,
    mutate: mutateDistributor,
    error: mutateDistributorError
  } = useMutation({
    mutationFn: (changes: Distributor) => editDistributor(changes)
  });

  const {
    isPending: isMutatingRetailer,
    mutate: mutateRetailer,
    error: mutateRetailerError
  } = useMutation({
    mutationFn: async (changes: RetailerFormData) => {
      if (heroImage) {
        if (retailer?.heroImage) await deleteImage(retailer.heroImage);
        const heroImageId = await uploadImage(heroImage, { retailer: changes.slug, type: 'hero' });
        if (heroImageId) changes.heroImage = heroImageId;
      }
      if (heroImage === null && retailer?.heroImage) {
        await deleteImage(retailer.heroImage);
        changes.heroImage = '';
      }
      if (logo) {
        if (retailer?.logo) await deleteImage(retailer.logo);
        const logoImageId = await uploadImage(logo, { retailer: changes.slug, type: 'logo' });
        if (logoImageId) changes.logo = logoImageId;
      }
      if (logo === null && retailer?.logo) {
        await deleteImage(retailer.logo);
        changes.logo = '';
      }
      changes.url &&= changes.url.replace(/^https?:\/\/www\./, 'https://').replace(/\/$/, '');
      if (isBrandOnlineStore) changes.isBrandOnlineStore = true;
      if (isDistributorOnlineStore) changes.isDistributorOnlineStore = true;
      return retailerId
        ? editRetailer({
            ...changes,
            _id: retailerId,
            locations: locations.filter((location) => location.address)
          })
        : createRetailer({ ...changes, locations: locations.filter((location) => location.address) });
    },
    onSuccess: (data) => {
      let hasUpdatedDistributors = false;
      if (distributors?.length) {
        distributors.forEach((distributor) => {
          if (distributor._id) {
            const wasListingRetailer = (distributor.retailers as string[]).includes(String(data._id));
            const isSelected = selectedDistributors.some(({ _id }) => _id === distributor._id);
            if ((wasListingRetailer && !isSelected) || (!wasListingRetailer && isSelected)) {
              mutateDistributor({
                ...distributor,
                retailers: wasListingRetailer
                  ? (distributor.retailers as string[]).filter((id) => id !== data._id)
                  : (distributor.retailers as string[]).concat(data._id)
              });
              hasUpdatedDistributors = true;
            }
          }
        });
      }
      let hasUpdatedBrands = false;
      if (brands?.length) {
        brands.forEach((brand) => {
          if (brand._id) {
            const wasListingRetailer = (brand.retailers as string[]).includes(String(data._id));
            const isSelected = selectedBrands.some(({ _id }) => _id === brand._id);
            if ((wasListingRetailer && !isSelected) || (!wasListingRetailer && isSelected)) {
              mutateBrand({
                ...brand,
                retailers: wasListingRetailer
                  ? (brand.retailers as string[]).filter((id) => id !== data._id)
                  : (brand.retailers as string[]).concat(data._id)
              });
              hasUpdatedBrands = true;
            }
          }
        });
      }
      if (retailerId) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchRetailer', retailerId] });
      if (hasUpdatedDistributors) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllDistributors'] });
      if (hasUpdatedBrands) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllBrands'] });
      queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllRetailers'] });
      if (onSuccess) {
        onSuccess(data);
      } else {
        navigate(-1);
      }
    }
  });

  const {
    isPending: isMutatingBrand,
    mutate: mutateBrand,
    error: mutateBrandError
  } = useMutation({
    mutationFn: (changes: Brand) => editBrand(changes)
  });

  const handleUrlChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trim().toLowerCase();
      const hasProtocol = ['https://', 'http://'].some((pref) => text.startsWith(pref));
      setValue('url', hasProtocol ? text : (`https://${text}` as string), { shouldDirty: true });
    },
    [setValue]
  );

  const isLoading = useMemo(
    () =>
      isFetchingRetailer ||
      isMutatingRetailer ||
      isFetchingAllBrands ||
      isFetchingAllDistributors ||
      isMutatingBrand ||
      isMutatingDistributor,
    [
      isFetchingRetailer,
      isMutatingRetailer,
      isFetchingAllBrands,
      isFetchingAllDistributors,
      isMutatingBrand,
      isMutatingDistributor
    ]
  );

  const error = useMemo(
    () =>
      fetchRetailerError ||
      fetchAllBrandsError ||
      fetchAllDistributorsError ||
      mutateRetailerError ||
      mutateBrandError ||
      mutateDistributorError,
    [
      fetchRetailerError,
      fetchAllBrandsError,
      fetchAllDistributorsError,
      mutateRetailerError,
      mutateBrandError,
      mutateDistributorError
    ]
  );

  const onSubmit = (data: RetailerFormData) => mutateRetailer(data);

  useEffect(() => {
    setIsSlugValid(!!retailer?.slug);
  }, [retailer?.slug]);

  useEffect(() => {
    setValue('brands', selectedBrands, { shouldDirty: true });
  }, [selectedBrands, setValue]);

  useEffect(() => {
    if (!locations?.length) setLocations([{ address: '', city: '', state: '', zipCode: '' }]);
  }, [locations]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Error error={error} />
      {!isLoading && !error ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            id='name'
            control={control}
            defaultValue={retailer?.name}
            error={!!errors.name}
            helperText={errors.name?.message}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
              setValue('name', e.target.value);
            }}
          />
          <Field
            id='slug'
            control={control}
            defaultValue={retailer?.slug}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
            }}
            onBlur={(e) =>
              fetchRetailerBySlug(e.target.value).then(({ _id }) => {
                if (!_id || _id === retailerId) {
                  setIsSlugValid(true);
                  clearErrors('slug');
                } else {
                  setIsSlugValid(false);
                  setError('slug', { type: 'unique', message: 'Slug already exists' }, { shouldFocus: true });
                }
              })
            }
            error={!!errors.slug}
            helperText={errors.slug?.message}
          />
          <Field
            id='url'
            type='url'
            sm={4}
            control={control}
            defaultValue={retailer?.url}
            error={!!errors.url}
            helperText={errors.url?.message}
            onChange={handleUrlChanges}
          />
          <FieldAutocomplete
            id='isArchived'
            sm={2}
            control={control}
            options={['true', 'false']}
            defaultValue={retailer?.isArchived ? 'true' : 'false'}
            error={!!errors.isArchived}
            helperText={errors.isArchived?.message}
            onChange={(_, value) => setValue('isArchived', value === 'true')}
          />
          <Field
            id='popularity'
            type='number'
            sm={3}
            control={control}
            defaultValue={retailer?.popularity}
            error={!!errors.popularity}
            helperText={errors.popularity?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('popularity', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('popularity', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <Field
            id='scraperPriority'
            type='number'
            sm={3}
            control={control}
            defaultValue={retailer?.scraperPriority}
            error={!!errors.scraperPriority}
            helperText={errors.scraperPriority?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('scraperPriority', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('scraperPriority', (value > 6 ? 6 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5 | 6);
            }}
          />
          <Field
            id='description'
            control={control}
            defaultValue={retailer?.description}
            sm={12}
            rows={6}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <ViewImageButton
            text='View Hero'
            imageId={retailer?.heroImage}
          />
          <EditImageButton
            key='Edit Hero'
            text='Hero'
            action={heroImage || retailer?.heroImage ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${retailer?.slug || 'retailer'}-hero.png`}
            setImage={setHeroImage}
          />
          <DeleteImageButton
            disabled={(!heroImage && !retailer?.heroImage) || heroImage === null}
            onDelete={() => setHeroImage(null)}
            text='Hero'
          />
          <ViewImageButton
            text='View Logo'
            imageId={retailer?.logo}
          />
          <EditImageButton
            key='Edit Logo'
            text='Logo'
            action={logo || retailer?.logo ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${retailer?.slug || 'retailer'}-logo.png`}
            setImage={setLogo}
          />
          <DeleteImageButton
            disabled={(!logo && !retailer?.logo) || logo === null}
            onDelete={() => setLogo(null)}
            text='Logo'
          />
          <FieldAutocomplete
            id='country'
            control={control}
            options={countries}
            defaultValue={retailer?.country}
            error={!!errors.country}
            helperText={errors.country?.message}
            onChange={(_, value) => setValue('country', value as Country)}
          />
          {locations.map((location, index) => (
            <LocationFields
              key={`location-${index}`}
              index={index}
              location={location}
              error={!!errors.locations}
              helperText={errors.locations?.message}
              showAddLocation={index === locations.length - 1}
              setLocations={setLocations}
            />
          ))}
          <Field
            id='email'
            type='email'
            control={control}
            defaultValue={retailer?.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Field
            id='phoneNumber'
            type='tel'
            control={control}
            defaultValue={retailer?.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
          {socialNetworks.map((socialNetwork) => (
            <Field
              id={socialNetwork}
              key={socialNetwork}
              label={socialNetwork.replace(/^./, (str) => str.toUpperCase())}
              control={control}
              defaultValue={retailer ? retailer[socialNetwork] : undefined}
              error={!!errors[socialNetwork]}
              helperText={errors[socialNetwork]?.message}
            />
          ))}
          <FieldAutocomplete
            id='brands'
            control={control}
            options={brands || []}
            value={selectedBrands}
            onChange={(_, value) => {
              if ((value as Brand[]).some(({ name }) => name === 'Other')) {
                return setIsEditBrandModalOpen(true);
              }
              const newSelectedBrands = (value as Brand[]).filter(({ name }) => name !== 'Other');
              setSelectedBrands(newSelectedBrands);
            }}
            getOptionLabel={(option) => (option as Brand).name}
            getOptionKey={(option) => (option as Brand).slug}
            multiple={true}
            error={!!errors.brands}
            helperText={errors.brands?.message}
          />
          <FieldAutocomplete
            id='distributors'
            control={control}
            options={distributors || []}
            value={selectedDistributors}
            onChange={(_, value) => {
              if ((value as Distributor[]).some(({ name }) => name === 'Other')) {
                return setIsEditDistributorModalOpen(true);
              }
              const newSelectedDistributors = (value as Distributor[]).filter(({ name }) => name !== 'Other');
              setSelectedDistributors(newSelectedDistributors);
            }}
            getOptionLabel={(option) => (option as Distributor).name}
            getOptionKey={(option) => (option as Distributor).slug}
            multiple={true}
            error={!!errors.distributors}
            helperText={errors.distributors?.message}
          />
          <Field
            id='notes'
            sm={12}
            rows={2}
            control={control}
            defaultValue={retailer?.notes}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
          <FormActions
            saveAction={handleSubmit(onSubmit)}
            cancelAction={onCancel}
            isSaveButtonDisabled={!isDirty}
          />
        </Form>
      ) : null}
      <EditBrandModal
        isOpen={isEditBrandModalOpen}
        onSuccess={({ _id, name, slug }) => {
          setSelectedBrands((prev) => [...prev, { _id, name, slug }]);
          setIsEditBrandModalOpen(false);
        }}
        onCancel={() => setIsEditBrandModalOpen(false)}
      />
      <EditDistributorModal
        isOpen={isEditDistributorModalOpen}
        onSuccess={({ _id, name, slug, retailers }) => {
          setSelectedDistributors((prev) => [...prev, { _id, name, slug, retailers }]);
          setIsEditDistributorModalOpen(false);
        }}
        onCancel={() => setIsEditDistributorModalOpen(false)}
      />
    </Container>
  );
};

export { RetailerForm };
