import { Brand, QueryParams } from '@/globals/types';
import { api } from '@/services';

const brandsApiPath = '/brands';

type BrandListResponse = {
  brands: Brand[];
  totalCount: number;
};

const useBrands = () => {
  const fetchAllBrands = async (
    params: QueryParams = { sortBy: 'name', sortDirection: 'asc' }
  ): Promise<BrandListResponse> => {
    const { data, headers } = await api.get(brandsApiPath, { params });
    return { brands: data, totalCount: headers['x-total-count'] };
  };

  const searchBrands = async (term: string, params?: QueryParams): Promise<BrandListResponse> => {
    const { data, headers } = await api.get(`${brandsApiPath}/search/${term}`, { params });
    return { brands: data, totalCount: headers['x-total-count'] };
  };

  const fetchBrand = async (brandId: string): Promise<Brand> => {
    const { data } = await api.get(`${brandsApiPath}/${brandId}`);
    return data;
  };

  const fetchBrandBySlug = async (slug: string): Promise<Brand> => {
    const { data } = await api.get(`${brandsApiPath}/by-slug/${slug}`);
    return data;
  };

  const createBrand = async (brand: Partial<Brand>): Promise<Brand> => {
    const { data } = await api.post(`${brandsApiPath}`, brand);
    return data;
  };

  const editBrand = async (brand: Partial<Brand> & { _id: string }): Promise<Brand> => {
    const { data } = await api.patch(`${brandsApiPath}/${brand._id}`, brand);
    return data;
  };

  const deleteBrand = async (brandId: string): Promise<void> => {
    await api.delete(`${brandsApiPath}/${brandId}`);
    return;
  };

  return { fetchAllBrands, searchBrands, fetchBrand, fetchBrandBySlug, createBrand, editBrand, deleteBrand };
};

export { useBrands };
