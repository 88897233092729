import { Box, Button, Dialog, Typography } from '@mui/material';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrivacyPolicyModal = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth='md'
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box
        paddingX={{ xs: 4, md: 7 }}
        paddingTop={{ xs: 4, md: 7 }}
      >
        <Typography variant='h4'>Audiophile Directory Privacy Policy</Typography>
        <Typography>
          <p>
            Effective Date: October 1st, 2024 Last Updated: October 1st, 2024 Audiophile Directory ("we," "our," or
            "us") respects your privacy and is committed to protecting the personal information that you share with us.
            This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit
            our website, https://audiophiledirectory.com (the “Site”), and use our services. Please read this Privacy
            Policy carefully. By accessing or using our Site, you acknowledge that you have read, understood, and agree
            to be bound by all the terms outlined in this Privacy Policy.
          </p>
          <p>
            1. <b>Information We Collect</b>
            <br />
            We collect various types of information in order to provide you with the best possible experience on our
            Site. This includes:
          </p>
          <p>
            1.1. <b>Personal Information</b>
            <br />
            When you sign up for our services (e.g., creating a profile, entering a sweepstakes, subscribing to our
            newsletter, or joining our directory), we may collect personal information such as:
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Business information (for brands, distributors, and dealers)</li>
              <li>Location data (such as your ZIP code or IP address)</li>
            </ul>
          </p>
          <p>
            1.2. <b>Non-Personal Information</b>
            <br />
            We may collect non-personal information about your visit to the Site. This information is typically used to
            improve user experience and includes:
            <ul>
              <li>Browser type</li>
              <li>Device type</li>
              <li>Operating system</li>
              <li>Pages viewed and time spent on our Site</li>
              <li>Referring URLs or search queries</li>
            </ul>
          </p>
          <p>
            1.3. <b>Cookies and Tracking Technologies</b>
            <br />
            We use cookies and similar tracking technologies to enhance your experience on the Site. Cookies are small
            data files that are placed on your device to track usage patterns, remember preferences, and collect
            information for analytics. You can control the use of cookies through your browser settings.
          </p>
          <p>
            2. <b>How We Use Your Information</b>
            <br />
            We may collect non-personal information about your visit to the Site. This information is typically used to
            improve user experience and includes:
            <ul>
              <li>To personalize your experience and show content relevant to your interests.</li>
              <li>To improve our Site and services through aggregated analytics and feedback.</li>
              <li>To communicate with you regarding updates, offers, newsletters, or service-related notices.</li>
              <li>To facilitate participation in sweepstakes, promotions, or other features.</li>
              <li>To protect the security of our Site and users.</li>
            </ul>
          </p>
          <p>
            3. <b>How We Share Your Information</b>
            <br />
            We do not sell or rent your personal information to third parties. However, we may share your information
            with:
            <ul>
              <li>
                <b>Service Providers:</b> Trusted third-party partners who assist us in operating the Site, conducting
                our business, or serving users, so long as they agree to keep this information confidential.
              </li>
              <li>
                <b>Legal Compliance:</b> We may disclose your information if required by law or in response to valid
                legal requests by public authorities (e.g., a court or government agency).
              </li>
              <li>
                <b>Business Transfers:</b> In the event of a merger, sale, or transfer of assets, we may share or
                transfer your information as part of the transaction.
              </li>
            </ul>
          </p>
          <p>
            4. <b>Your Privacy Rights</b>
          </p>
          <p>
            4.1. <b>General Data Protection Regulation (GDPR) Rights</b>
            <br />
            If you are a resident of the European Economic Area (EEA), you have the following rights under GDPR:
            <ul>
              <li>
                <b>Right of Access:</b> Request access to the personal information we hold about you.
              </li>
              <li>
                <b>Right to Rectification:</b> Request correction of any inaccuracies in your data.
              </li>
              <li>
                <b>Right to Erasure:</b> Request deletion of your personal information under certain conditions.
              </li>
              <li>
                <b>Right to Object:</b> Object to processing your data, including for direct marketing purposes.
              </li>
            </ul>
          </p>
          <p>
            4.2. <b>California Consumer Privacy Act (CCPA) Rights</b>
            <br />
            If you are a California resident, the CCPA gives you certain rights:
            <ul>
              <li>
                <b>Right to Know:</b> Request information about the personal data we collect, use, and share.
              </li>
              <li>
                <b>Right to Delete:</b> Request that we delete your personal data, subject to certain exceptions.
              </li>
              <li>
                <b>Right to Opt-Out</b>: Opt-out of the sale of your personal data (although we do not sell personal
                data).
              </li>
            </ul>
            To exercise any of these rights, please contact us at contact@audiophiledirectory.com.
          </p>
          <p>
            5. <b>Data Retention</b>
            <br />
            We will retain your personal information for as long as necessary to provide our services, comply with legal
            obligations, resolve disputes, and enforce agreements. When we no longer need your information, we will
            securely delete or anonymize it.
          </p>
          <p>
            6. <b>Security of Your Information</b>
            <br />
            We take reasonable measures to protect your information from unauthorized access, misuse, or disclosure.
            However, no method of transmission over the internet or electronic storage is completely secure, and we
            cannot guarantee absolute security.
          </p>
          <p>
            7. <b>Third-Party Links</b>
            <br />
            Our Site may contain links to third-party websites, such as brand pages, dealer sites, or forums. This
            Privacy Policy applies solely to information collected by Audiophile Directory. We are not responsible for
            the privacy practices of other websites and encourage you to review their privacy policies.
          </p>
          <p>
            8. <b>Children’s Privacy</b>
            <br />
            Our services are not intended for children under the age of 13, and we do not knowingly collect personal
            information from children. If we discover that we have inadvertently collected information from a child
            under 13, we will take steps to delete the information promptly.
          </p>
          <p>
            9. <b>Changes to This Privacy Policy</b>
            <br />
            We may update this Privacy Policy from time to time. When we do, we will post the updated policy on this
            page with the revised “Last Updated” date. Your continued use of the Site after such changes constitutes
            your acceptance of the new terms.
          </p>
          <p>
            10. <b>Contact Us</b>
            <br />
            If you have any questions or concerns regarding this Privacy Policy or wish to exercise your rights, please
            contact us at:
            <br />
            <b>Audiophile Directory</b>
            <br />
            883 Water Tower Way, Apt.202, Hypoluxo, FL 33462, United States
            <br />
            contact@audiophiledirectory.com
            <br />
            +1 (917) 891-1456
          </p>
        </Typography>
      </Box>
      <Box
        padding={5}
        width='100%'
        display='flex'
        justifyContent='center'
      >
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => setIsOpen(false)}
          sx={{ minWidth: 300 }}
        >
          Ok, understood
        </Button>
      </Box>
    </Dialog>
  );
};

export { PrivacyPolicyModal };
