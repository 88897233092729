import { Pagination, PaginationItem } from '@mui/material';
import { characters } from '@/globals/constants';
import { Character } from '@/globals/types/Character';
import { useScreen } from '@/hooks';

type Props = {
  colSpan: number;
  character: Character;
  onChange: (page: number) => void;
};

const TableAlphabeticalIndex = ({ character, onChange }: Props) => {
  const { isMobile, isTablet } = useScreen();

  return (
    <Pagination
      count={characters.length}
      page={characters.indexOf(character) + 1}
      siblingCount={isMobile ? 2 : isTablet ? 3 : 7}
      onChange={(_e, page) => onChange(page)}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          page={characters[(item.page || 1) - 1]}
        />
      )}
      content='brands'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 2,
        pb: 2,
        textAlign: 'center'
      }}
    />
  );
};

export { TableAlphabeticalIndex };
