import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Button, Container, IconButton, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useScreen } from '@/hooks';
import { PrivacyPolicyModal, SweepstakesModal, TermsAndConditionsModal } from '../socials';

const Footer = () => {
  const { isMobile } = useScreen();

  const [isPrivacyPolicyModalOpened, setIsPrivacyPolicyModalOpened] = useState(false);
  const [isTermsAndConditionsModalOpened, setIsTermsAndConditionsModalOpened] = useState(false);
  const [isSweepstakesModalOpened, setIsSweepstakesModalOpened] = useState(false);

  return (
    <Box
      sx={{
        bgcolor: '#0A1B3D',
        color: 'white',
        width: '100%'
      }}
    >
      <Container sx={{ py: 4 }}>
        <Box
          display='flex'
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems={{ xs: 'center', md: 'start' }}
          px={{ xs: 3, md: 10 }}
          flexWrap='wrap'
        >
          <Box
            display='flex'
            alignItems={{ xs: 'center', md: 'start' }}
            justifyContent={{ xs: 'center', md: 'start' }}
            flexDirection='column'
            pb={{ xs: 2, md: 0 }}
          >
            <img
              src='/assets/logo-negative.svg'
              alt='Audiophile Directory Logo'
              style={{ height: '50px', width: '135px', marginBottom: '20px' }}
              role='img'
            />
            <Box
              display='flex'
              flexDirection='row'
              alignSelf={{ xs: 'center', md: 'start' }}
            >
              <Typography
                variant='body2'
                sx={{ mb: 2, mr: 1 }}
              >
                Email:
              </Typography>
              <Link
                href='mailto:contact@audiophiledirectory.com'
                underline='hover'
                color='white'
                aria-label='Send email to contact@audiophiledirectory.com'
              >
                <Typography
                  variant='body2'
                  sx={{ mb: 2 }}
                >
                  contact@audiophiledirectory.com
                </Typography>
              </Link>
            </Box>
            <Stack
              direction='row'
              spacing={1}
            >
              <Link
                href='https://www.facebook.com/audiophiledirectory'
                target='_blank'
                rel='noopener noreferrer'
              >
                <IconButton
                  color='inherit'
                  size='small'
                  sx={{ color: '#fff' }}
                  aria-label='Visit our Facebook page'
                >
                  <FacebookIcon />
                </IconButton>
              </Link>
              <Link
                href='https://www.instagram.com/audiophiledirectory'
                target='_blank'
                rel='noopener noreferrer'
              >
                <IconButton
                  color='inherit'
                  size='small'
                  sx={{ color: '#fff' }}
                  aria-label='Visit our Instagram profile'
                >
                  <InstagramIcon />
                </IconButton>
              </Link>
              {/* <Link
                href='https://wa.link/lr94ju'
                target='_blank'
                rel='noopener noreferrer'
              >
                <IconButton
                  color='inherit'
                  size='small'
                  sx={{ color: '#fff' }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </Link> */}
            </Stack>
          </Box>
          <Box maxWidth={{ xs: '100%', sm: 400 }}>
            <Typography
              fontSize={{ xs: 19, sm: 21 }}
              textAlign={{ xs: 'center', md: 'start' }}
              mb={1}
            >
              Enter Now and Join Our <strong style={{ fontSize: 25 }}>Sweepstakes</strong>
            </Typography>
            <Typography
              variant='body2'
              textAlign={{ xs: 'center', md: 'start' }}
              sx={{ mb: 2 }}
            >
              Join Today and get your entry into our exclusive sweepstakes! Don't miss your shot at amazing prizes—it's
              free and easy!
            </Typography>
            <Box
              width='100%'
              display='flex'
              justifyContent={{ xs: 'center', md: 'start' }}
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setIsSweepstakesModalOpened(true)}
                aria-label='Open sweepstakes information modal'
              >
                Join Our Sweepstakes
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ bgcolor: '#14295F', py: 1 }}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: 'center',
              flexWrap: 'wrap',
              px: { xs: 2, sm: 5 },
              width: '100%'
            }}
          >
            <Typography variant='body2'>Audiophile Directory LLC. © 2024.</Typography>
            <Box>
              <Button
                variant='text'
                sx={{ textDecoration: 'none', color: 'white' }}
                onClick={() => setIsPrivacyPolicyModalOpened(true)}
                aria-label='View privacy policy'
              >
                Privacy Policy
              </Button>
              {!isMobile ? '|' : ''}
              <Button
                variant='text'
                sx={{ textDecoration: 'none', color: 'white' }}
                onClick={() => setIsTermsAndConditionsModalOpened(true)}
                aria-label='View terms and conditions'
              >
                Terms & Conditions
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <PrivacyPolicyModal
        isOpen={isPrivacyPolicyModalOpened}
        setIsOpen={setIsPrivacyPolicyModalOpened}
      />
      <TermsAndConditionsModal
        isOpen={isTermsAndConditionsModalOpened}
        setIsOpen={setIsTermsAndConditionsModalOpened}
      />
      <SweepstakesModal
        isOpen={isSweepstakesModalOpened}
        setIsOpen={setIsSweepstakesModalOpened}
      />
    </Box>
  );
};

export { Footer };
