import { z } from 'zod';

const brandSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  slug: z.string().min(1, 'Slug is required'),
  url: z.union([z.string().url('Must be a valid URL'), z.literal('')]).optional(),
  popularity: z.union([z.number().min(1).max(5), z.literal(''), z.null()]).optional(),
  isHomeTheaterBrand: z.boolean().optional(),
  description: z.string().optional(),
  country: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  address: z.string().optional(),
  heroImage: z.union([z.string(), z.instanceof(File)]).optional(),
  logo: z.union([z.string(), z.instanceof(File)]).optional(),
  // TODO: otherImages: z.array(z.string().url('Must be a valid URL')).optional(),
  email: z.union([z.string().email('Must be a valid email'), z.literal('')]).optional(),
  whatsApp: z.string().optional(),
  instagram: z.string().optional(),
  facebook: z.string().optional(),
  youtube: z.string().optional(),
  tikTok: z.string().optional(),
  pinterest: z.string().optional(),
  discord: z.string().optional(),
  x: z.string().optional(),
  linkedIn: z.string().optional(),
  phoneNumber: z
    .union([
      z
        .string()
        .regex(/^\+?[\d()\s-]+$/, 'Invalid phone number')
        .min(10, 'Invalid phone number'),
      z.literal('')
    ])
    .optional(),
  scraperPriority: z.union([z.number().min(1).max(6), z.literal(''), z.null()]).optional(),
  scraperStatus: z
    .union([z.literal('pending'), z.literal('in progress'), z.literal('blocked'), z.literal('ready'), z.null()])
    .optional(),
  notes: z.string().optional(),
  isArchived: z.union([z.literal('true'), z.literal('false'), z.null()]),
  retailers: z.array(z.string()).optional(),
  onlineStores: z.array(z.string()).optional()
});

export { brandSchema };
