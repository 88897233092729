import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Grid2, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';

type ExtractArrayType<T> = T extends (infer U)[] ? U : T;
type ExtractType<T> = T extends undefined ? never : ExtractArrayType<T>;

type Props<T extends FieldValues> = {
  id: Path<T>;
  label?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
  sm?: 2 | 3 | 6 | 12;
  options: readonly (string | (ExtractType<PathValue<T, Path<T>>> & { selected?: boolean }))[];
  multiple?: boolean;
  value?: PathValue<T, Path<T>>;
  getOptionLabel?: (option: string | (ExtractType<PathValue<T, Path<T>>> & { selected?: boolean })) => string;
  getOptionKey?: (option: string | (ExtractType<PathValue<T, Path<T>>> & { selected?: boolean })) => string;
  onChange?:
    | ((
        event: React.SyntheticEvent,
        value: unknown,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<unknown> | undefined
      ) => void)
    | undefined;
  error: boolean;
  helperText?: string;
};

const FieldAutocomplete = <T extends FieldValues>({
  id,
  label = id.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
  control,
  defaultValue,
  multiple,
  sm,
  options,
  value,
  getOptionLabel,
  getOptionKey,
  onChange,
  error,
  helperText
}: Props<T>) => (
  <Grid2
    size={{ xs: 12, sm }}
    key={id}
  >
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              id={id}
              label={label}
              variant='outlined'
              fullWidth
              error={error}
              helperText={helperText}
            />
          )}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange || field.onChange}
          multiple={multiple}
          options={options}
          getOptionKey={getOptionKey}
          getOptionLabel={getOptionLabel}
          disablePortal
        />
      )}
    />
  </Grid2>
);

export { FieldAutocomplete };
