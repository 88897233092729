import { Distributor, QueryParams } from '@/globals/types';
import { api } from '@/services';

const distributorsApiPath = '/distributors';

type DistributorListResponse = {
  distributors: Distributor[];
  totalCount: number;
};

const useDistributors = () => {
  const fetchAllDistributors = async (
    params: QueryParams = { sortBy: 'name', sortDirection: 'asc' }
  ): Promise<DistributorListResponse> => {
    const { data, headers } = await api.get(distributorsApiPath, { params });
    return { distributors: data, totalCount: headers['x-total-count'] };
  };

  const searchDistributors = async (term: string, params?: QueryParams): Promise<DistributorListResponse> => {
    const { data, headers } = await api.get(`${distributorsApiPath}/search/${term}`, { params });
    return { distributors: data, totalCount: headers['x-total-count'] };
  };

  const fetchDistributor = async (distributorId: string): Promise<Distributor> => {
    const { data } = await api.get(`${distributorsApiPath}/${distributorId}`);
    return data;
  };

  const fetchDistributorBySlug = async (slug: string): Promise<Distributor> => {
    const { data } = await api.get(`${distributorsApiPath}/by-slug/${slug}`);
    return data;
  };

  const createDistributor = async (distributor: Partial<Distributor>): Promise<Distributor> => {
    const { data } = await api.post(`${distributorsApiPath}`, distributor);
    return data;
  };

  const editDistributor = async (distributor: Partial<Distributor> & { _id: string }): Promise<Distributor> => {
    const { data } = await api.patch(`${distributorsApiPath}/${distributor._id}`, distributor);
    return data;
  };

  const deleteDistributor = async (distributorId: string): Promise<void> => {
    await api.delete(`${distributorsApiPath}/${distributorId}`);
    return;
  };

  return {
    fetchAllDistributors,
    searchDistributors,
    fetchDistributor,
    fetchDistributorBySlug,
    createDistributor,
    editDistributor,
    deleteDistributor
  };
};

export { useDistributors };
