import { Pagination } from '@mui/material';
import { useScreen } from '@/hooks';

type Props = {
  pages: number;
  page: number;
  onChange: (page: number) => void;
};

const TablePagination = ({ pages, page, onChange }: Props) => {
  const { isMobile, isTablet } = useScreen();

  return (
    <Pagination
      count={pages}
      page={page}
      siblingCount={isMobile ? 2 : isTablet ? 3 : 7}
      onChange={(_e, page) => onChange(page)}
      content='brands'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        textAlign: 'center'
      }}
    />
  );
};

export { TablePagination };
