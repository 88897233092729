import { z } from 'zod';

const locationSchema = z.object({
  address: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  email: z.union([z.string().email('Must be a valid email'), z.literal('')]).optional(),
  whatsApp: z.string().optional(),
  instagram: z.string().optional(),
  facebook: z.string().optional(),
  youtube: z.string().optional(),
  tikTok: z.string().optional(),
  pinterest: z.string().optional(),
  discord: z.string().optional(),
  x: z.string().optional(),
  linkedIn: z.string().optional(),
  phoneNumber: z
    .union([
      z
        .string()
        .regex(/^\+?[\d()\s-]+$/, 'Invalid phone number')
        .min(10, 'Invalid phone number'),
      z.literal('')
    ])
    .optional(),
  // TODO: location: Add Location here
  // TODO: openingHours: Add Opening Hours here
});

export { locationSchema };
