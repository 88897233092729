import { Facebook, Instagram } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';

const FollowUsVertical = () => (
  <Box
    component="nav"
    aria-label="Social media links"
    position='absolute'
    left={{ xs: 12, md: 40 }}
    top={{ xs: 12, md: 40 }}
    alignItems='center'
  >
    <Box
      component="div"
      sx={{ writingMode: 'vertical-lr', color: '#fff' }}
      role="heading"
      aria-level={2}
    >
      <Typography
        fontSize={18}
        fontFamily='"Source Sans Pro", sans-serif'
        fontWeight={400}
        fontStyle='italic'
        paddingBottom={2}
      >
        Follow us
      </Typography>
    </Box>
    <Box
      component="ul"
      sx={{
        color: '#fff',
        padding: 0,
        margin: 0,
        listStyle: 'none'
      }}
    >
      <Box component="li" paddingBottom={1}>
        <Link
          href='https://www.facebook.com/audiophiledirectory'
          target='_blank'
          rel='noopener noreferrer'
          aria-label="Visit our Facebook page"
          sx={{
            '&:focus': {
              outline: '2px solid #fff',
              outlineOffset: '2px'
            }
          }}
        >
          <IconButton
            size='small'
            aria-label="Visit our Facebook page"
            sx={{
              color: '#fff',
              transform: 'rotate(90deg)',
              '&:focus': {
                outline: 'none'
              }
            }}
          >
            <Facebook fontSize='small' aria-hidden="true" />
          </IconButton>
        </Link>
      </Box>
      <Box component="li">
        <Link
          href='https://www.instagram.com/audiophiledirectory'
          target='_blank'
          rel='noopener noreferrer'
          aria-label="Visit our Instagram page"
          sx={{
            '&:focus': {
              outline: '2px solid #fff',
              outlineOffset: '2px'
            }
          }}
        >
          <IconButton
            size='small'
            aria-label="Visit our Instagram page"
            sx={{
              color: '#fff',
              transform: 'rotate(90deg)',
              '&:focus': {
                outline: 'none'
              }
            }}
          >
            <Instagram fontSize='small' aria-hidden="true" />
          </IconButton>
        </Link>
      </Box>
    </Box>
  </Box>
);

export { FollowUsVertical };
