import { Box, Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split('/').filter((route) => route !== '');
  const routes = path.map((path, index, array) => ({
    href: `/${array.slice(0, index + 1).join('/')}`,
    label: path.charAt(0).toUpperCase() + path.slice(1)
  }));

  return (
    <Box paddingBottom={3}>
      <MuiBreadcrumbs aria-label='breadcrumb'>
        {routes.map(({ href, label }, index, array) =>
          index !== array.length - 1 ? (
            <Link
              key={index}
              underline='hover'
              color='inherit'
              onClick={() => navigate(href)}
            >
              {label}
            </Link>
          ) : (
            <Typography
              key={index}
              color='text.primary'
            >
              {label}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
    </Box>
  );
};

export { Breadcrumbs };
