import { Box, Dialog, Paper, Typography } from '@mui/material';
import { Retailer } from '@/globals/types';
import { RetailerForm } from '.';

type Props = {
  isOpen: boolean;
  retailerId?: string;
  onSuccess: (retailer: Retailer) => void;
  onCancel: () => void;
  isBrandOnlineStore?: boolean;
  isDistributorOnlineStore?: boolean;
};

const EditRetailerModal = ({
  isOpen,
  retailerId,
  onSuccess,
  onCancel,
  isBrandOnlineStore,
  isDistributorOnlineStore
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth={false}
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Paper>
        <Box padding={3}>
          <Typography
            variant='h4'
            gutterBottom
          >
            {retailerId ? 'Edit' : 'Create'} Retailer
          </Typography>
          <RetailerForm
            retailerId={retailerId}
            onSuccess={onSuccess}
            onCancel={onCancel}
            isBrandOnlineStore={isBrandOnlineStore}
            isDistributorOnlineStore={isDistributorOnlineStore}
          />
        </Box>
      </Paper>
    </Dialog>
  );
};

export { EditRetailerModal };
