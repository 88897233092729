import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { countries, socialNetworks } from '@/globals/constants';
import { Brand, Country, Distributor, Retailer } from '@/globals/types';
import { useBrands, useCloudflare, useDistributors, useRetailers } from '@/hooks';
import { brandSchema } from '@/pages/admin/brands/schemas';
import {
  DeleteImageButton,
  EditDistributorModal,
  EditImageButton,
  EditRetailerModal,
  Error,
  Field,
  FieldAutocomplete,
  Form,
  FormActions,
  Loading,
  ViewImageButton
} from '.';

type Props = {
  brandId?: string;
  onSuccess?: (brand: Brand) => void;
  onCancel?: () => void;
};

const BrandForm = ({ brandId, onSuccess, onCancel }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createBrand, editBrand, fetchBrand, fetchBrandBySlug } = useBrands();
  const { fetchAllRetailers } = useRetailers();
  const { fetchAllDistributors, editDistributor } = useDistributors();
  const { uploadImage, deleteImage } = useCloudflare();

  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([]);
  const [isEditRetailerModalOpen, setIsEditRetailerModalOpen] = useState(false);
  const [isEditOnlineStoreModalOpen, setIsEditOnlineStoreModalOpen] = useState(false);
  const [selectedDistributors, setSelectedDistributors] = useState<Distributor[]>([]);
  const [isEditDistributorModalOpen, setIsEditDistributorModalOpen] = useState(false);
  const [selectedOnlineStores, setSelectedOnlineStores] = useState<Retailer[]>([]);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [logo, setLogo] = useState<File | null>();
  const [heroImage, setHeroImage] = useState<File | null>();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm<Brand & { distributors: Pick<Distributor, '_id' | 'name' | 'slug' | 'brands' | 'retailers'>[] }>({
    resolver: zodResolver(brandSchema)
  });

  const {
    data: brand,
    isLoading: isFetchingBrand,
    error: fetchBrandError
  } = useQuery({
    queryKey: ['fetchBrand', brandId],
    enabled: !!brandId,
    refetchOnWindowFocus: false,
    queryFn: () => fetchBrand(brandId as string)
  });

  const {
    data: retailers,
    isLoading: isFetchingAllRetailers,
    error: fetchAllRetailersError
  } = useQuery({
    queryKey: ['fetchAllRetailers'],
    enabled: !brandId || !!brand,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allRetailers = (await fetchAllRetailers()).retailers.map(({ _id, name, slug }) => ({ _id, name, slug }));
      setSelectedRetailers((prev) =>
        allRetailers.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (brand?.retailers as string[]) || []).includes(_id)
        )
      );
      setSelectedOnlineStores((prev) =>
        allRetailers.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (brand?.onlineStores as string[]) || []).includes(_id)
        )
      );
      return allRetailers.concat({ _id: '', name: 'Other', slug: '' });
    }
  });

  const {
    data: distributors,
    isLoading: isFetchingAllDistributors,
    error: fetchAllDistributorsError
  } = useQuery({
    queryKey: ['fetchAllDistributors'],
    enabled: !brandId || !!brand,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allDistributors = (await fetchAllDistributors()).distributors.map(({ _id, name, slug, brands }) => ({
        _id,
        name,
        slug,
        brands
      }));
      setSelectedDistributors((prev) =>
        allDistributors.filter(({ _id, brands }) =>
          prev.length ? prev.some(({ _id: prevId }) => _id === prevId) : (brands as string[]).includes(brandId || '')
        )
      );
      return allDistributors.concat({ _id: '', name: 'Other', slug: '', brands: [] });
    }
  });

  const {
    isPending: isMutatingDistributor,
    mutate: mutateDistributor,
    error: mutateDistributorError
  } = useMutation({
    mutationFn: (changes: Distributor) => editDistributor(changes)
  });

  const {
    isPending: isMutatingBrand,
    mutate: mutateBrand,
    error: mutateBrandError
  } = useMutation({
    mutationFn: async (changes: Brand) => {
      if (heroImage) {
        if (brand?.heroImage) await deleteImage(brand.heroImage);
        const heroImageId = await uploadImage(heroImage, { brand: changes.slug, type: 'hero' });
        if (heroImageId) changes.heroImage = heroImageId;
      }
      if (heroImage === null && brand?.heroImage) {
        await deleteImage(brand.heroImage);
        changes.heroImage = '';
      }
      if (logo) {
        if (brand?.logo) await deleteImage(brand.logo);
        const logoImageId = await uploadImage(logo, { brand: changes.slug, type: 'logo' });
        if (logoImageId) changes.logo = logoImageId;
      }
      if (logo === null && brand?.logo) {
        await deleteImage(brand.logo);
        changes.logo = '';
      }
      changes.url &&= changes.url.replace(/^https?:\/\/www\./, 'https://').replace(/\/$/, '');
      return brandId ? editBrand({ ...changes, _id: brandId }) : createBrand({ ...changes });
    },
    onSuccess: (data) => {
      let hasUpdatedDistributors = false;
      if (distributors?.length) {
        distributors.forEach((distributor) => {
          if (distributor._id) {
            const wasListingBrand = (distributor.brands as string[]).includes(String(data._id));
            const isSelected = selectedDistributors.some(({ _id }) => _id === distributor._id);
            if ((wasListingBrand && !isSelected) || (!wasListingBrand && isSelected)) {
              mutateDistributor({
                ...distributor,
                brands: wasListingBrand
                  ? (distributor.brands as string[]).filter((id) => id !== data._id)
                  : (distributor.brands as string[]).concat(data._id)
              });
              hasUpdatedDistributors = true;
            }
          }
        });
      }
      if (brandId) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchBrand', data._id] });
      if (hasUpdatedDistributors) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllDistributors'] });
      queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllBrands'] });
      if (onSuccess) {
        onSuccess(data);
      } else {
        navigate(-1);
      }
    }
  });

  const handleUrlChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trim().toLowerCase();
      setValue('url', `${!['https://', 'http://'].some((pref) => text.startsWith(pref)) ? 'https://' : ''}${text}`);
    },
    [setValue]
  );

  const isLoading = useMemo(
    () =>
      isFetchingBrand ||
      isFetchingAllRetailers ||
      isFetchingAllDistributors ||
      isMutatingBrand ||
      isMutatingDistributor,
    [isFetchingBrand, isFetchingAllRetailers, isFetchingAllDistributors, isMutatingBrand, isMutatingDistributor]
  );

  const error = useMemo(
    () =>
      fetchBrandError ||
      fetchAllRetailersError ||
      fetchAllDistributorsError ||
      mutateBrandError ||
      mutateDistributorError,
    [fetchBrandError, fetchAllRetailersError, fetchAllDistributorsError, mutateBrandError, mutateDistributorError]
  );

  const onSubmit = (data: Brand) => mutateBrand(data);

  useEffect(() => {
    setIsSlugValid(!!brand?.slug);
  }, [brand?.slug]);

  useEffect(() => {
    setValue(
      'retailers',
      selectedRetailers.map(({ _id }) => String(_id)),
      { shouldDirty: true }
    );
  }, [selectedRetailers, setValue]);

  useEffect(() => {
    setValue('distributors', selectedDistributors, { shouldDirty: true });
  }, [selectedDistributors, setValue]);

  useEffect(() => {
    setValue('onlineStores', selectedOnlineStores, { shouldDirty: true });
  }, [selectedOnlineStores, setValue]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Error error={error} />
      {!isLoading && !error ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            id='name'
            control={control}
            defaultValue={brand?.name}
            error={!!errors.name}
            helperText={errors.name?.message}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
              setValue('name', e.target.value);
            }}
          />
          <Field
            id='slug'
            control={control}
            defaultValue={brand?.slug}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
            }}
            onBlur={(e) =>
              fetchBrandBySlug(e.target.value).then(({ _id }) => {
                if (!_id || _id === brandId) {
                  setIsSlugValid(true);
                  clearErrors('slug');
                } else {
                  setIsSlugValid(false);
                  setError('slug', { type: 'unique', message: 'Slug already exists' }, { shouldFocus: true });
                }
              })
            }
            error={!!errors.slug}
            helperText={errors.slug?.message}
          />
          <Field
            id='url'
            type='url'
            sm={4}
            control={control}
            defaultValue={brand?.url}
            error={!!errors.url}
            helperText={errors.url?.message}
            onChange={handleUrlChanges}
          />
          <FieldAutocomplete
            id='isArchived'
            sm={2}
            control={control}
            options={['true', 'false']}
            defaultValue={brand?.isArchived ? 'true' : 'false'}
            error={!!errors.isArchived}
            helperText={errors.isArchived?.message}
            onChange={(_, value) => setValue('isArchived', value === 'true')}
          />
          <Field
            id='popularity'
            type='number'
            sm={2}
            control={control}
            defaultValue={brand?.popularity}
            error={!!errors.popularity}
            helperText={errors.popularity?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('popularity', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('popularity', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <Field
            id='scraperPriority'
            type='number'
            sm={2}
            control={control}
            defaultValue={brand?.scraperPriority}
            error={!!errors.scraperPriority}
            helperText={errors.scraperPriority?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('scraperPriority', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('scraperPriority', (value > 6 ? 6 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5 | 6);
            }}
          />
          <FieldAutocomplete
            id='scraperStatus'
            sm={2}
            control={control}
            options={['pending', 'in progress', 'blocked', 'ready']}
            defaultValue={brand?.scraperStatus}
            error={!!errors.scraperStatus}
            helperText={errors.scraperStatus?.message}
            onChange={(_, value) => setValue('scraperStatus', value as 'pending' | 'in progress' | 'blocked' | 'ready')}
          />
          <Field
            id='description'
            control={control}
            defaultValue={brand?.description}
            sm={12}
            rows={6}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <ViewImageButton
            text='View Hero'
            imageId={brand?.heroImage}
          />
          <EditImageButton
            key='Edit Hero'
            text='Hero'
            action={heroImage || brand?.heroImage ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${brand?.slug || 'brand'}-hero.png`}
            setImage={setHeroImage}
          />
          <DeleteImageButton
            disabled={(!heroImage && !brand?.heroImage) || heroImage === null}
            onDelete={() => setHeroImage(null)}
            text='Hero'
          />
          <ViewImageButton
            text='View Logo'
            imageId={brand?.logo}
          />
          <EditImageButton
            key='Edit Logo'
            text='Logo'
            action={logo || brand?.logo ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${brand?.slug || 'brand'}-logo.png`}
            setImage={setLogo}
          />
          <DeleteImageButton
            disabled={(!logo && !brand?.logo) || logo === null}
            onDelete={() => setLogo(null)}
            text='Logo'
          />
          <FieldAutocomplete
            id='country'
            sm={6}
            control={control}
            options={countries}
            defaultValue={brand?.country}
            error={!!errors.country}
            helperText={errors.country?.message}
            onChange={(_, value) => setValue('country', value as Country)}
          />
          <Field
            id='state'
            control={control}
            defaultValue={brand?.state}
            error={!!errors.state}
            helperText={errors.state?.message}
          />
          <Field
            id='city'
            control={control}
            defaultValue={brand?.city}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <Field
            id='zipCode'
            control={control}
            defaultValue={brand?.zipCode}
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
          />
          <Field
            id='address'
            control={control}
            defaultValue={brand?.address}
            error={!!errors.address}
            helperText={errors.address?.message}
          />
          <Field
            id='email'
            type='email'
            control={control}
            defaultValue={brand?.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Field
            id='phoneNumber'
            type='tel'
            control={control}
            defaultValue={brand?.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
          {socialNetworks.map((socialNetwork) => (
            <Field
              id={socialNetwork}
              key={socialNetwork}
              label={socialNetwork.replace(/^./, (str) => str.toUpperCase())}
              control={control}
              defaultValue={brand ? brand[socialNetwork] : undefined}
              error={!!errors[socialNetwork]}
              helperText={errors[socialNetwork]?.message}
            />
          ))}
          <FieldAutocomplete
            id='onlineStores'
            sm={12}
            control={control}
            options={retailers || []}
            value={selectedOnlineStores}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsEditOnlineStoreModalOpen(true);
              }
              const newSelectedOnlineStores = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedOnlineStores(newSelectedOnlineStores);
            }}
            getOptionLabel={(option) => (option as Retailer).name}
            getOptionKey={(option) => (option as Retailer).slug}
            multiple={true}
            error={!!errors.onlineStores}
            helperText={errors.onlineStores?.message}
          />
          <FieldAutocomplete
            id='retailers'
            sm={12}
            control={control}
            options={retailers || []}
            value={selectedRetailers}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsEditRetailerModalOpen(true);
              }
              const newSelectedRetailers = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedRetailers(newSelectedRetailers);
            }}
            getOptionLabel={(option) => (option as Retailer).name}
            getOptionKey={(option) => (option as Retailer).slug}
            multiple={true}
            error={!!errors.retailers}
            helperText={errors.retailers?.message}
          />
          <FieldAutocomplete
            id='distributors'
            control={control}
            options={distributors || []}
            value={selectedDistributors}
            onChange={(_, value) => {
              if ((value as Distributor[]).some(({ name }) => name === 'Other')) {
                return setIsEditDistributorModalOpen(true);
              }
              const newSelectedDistributors = (value as Distributor[]).filter(({ name }) => name !== 'Other');
              setSelectedDistributors(newSelectedDistributors);
            }}
            getOptionLabel={(option) => (option as Distributor).name}
            getOptionKey={(option) => (option as Distributor).slug}
            multiple={true}
            error={!!errors.distributors}
            helperText={errors.distributors?.message}
          />
          <Field
            id='notes'
            sm={12}
            rows={2}
            control={control}
            defaultValue={brand?.notes}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
          <FormActions
            saveAction={handleSubmit(onSubmit)}
            cancelAction={onCancel}
            isSaveButtonDisabled={!isDirty}
          />
        </Form>
      ) : null}
      <EditRetailerModal
        isOpen={isEditRetailerModalOpen || isEditOnlineStoreModalOpen}
        onSuccess={({ _id, name, slug }) => {
          setSelectedRetailers((prev) => [...prev, { _id, name, slug }]);
          setIsEditRetailerModalOpen(false);
          setIsEditOnlineStoreModalOpen(false);
        }}
        onCancel={() => {
          setIsEditRetailerModalOpen(false);
          setIsEditOnlineStoreModalOpen(false);
        }}
        isBrandOnlineStore={isEditOnlineStoreModalOpen}
      />
      <EditDistributorModal
        isOpen={isEditDistributorModalOpen}
        onSuccess={({ _id, name, slug, brands }) => {
          setSelectedDistributors((prev) => [...prev, { _id, name, slug, brands }]);
          setIsEditDistributorModalOpen(false);
        }}
        onCancel={() => setIsEditDistributorModalOpen(false)}
      />
    </Container>
  );
};

export { BrandForm };
