import { useParams } from 'react-router-dom';
import { RetailerForm, Paper } from '@/components/admin';

const AdminEditRetailer = () => {
  const { retailerId } = useParams<{ retailerId?: string }>();

  return (
    <Paper title={`${retailerId ? 'Edit' : 'Create'} Retailer`}>
      <RetailerForm retailerId={retailerId} />
    </Paper>
  );
};

export { AdminEditRetailer };
