import { Box, Container, Paper as MuiPaper, Typography } from '@mui/material';
import { Breadcrumbs } from '.';

type Props = {
  title: string;
  children: React.ReactNode;
};

const Paper = ({ title, children }: Props) => (
  <Container>
    <Box padding={3}>
      <MuiPaper>
        <Box padding={3}>
          <Breadcrumbs />
          <Typography
            variant='h4'
            gutterBottom
          >
            {title}
          </Typography>
          {children}
        </Box>
      </MuiPaper>
    </Box>
  </Container>
);

export { Paper };
