import { useParams } from 'react-router-dom';
import { BrandForm, Paper } from '@/components/admin';

const AdminEditBrand = () => {
  const { brandId } = useParams<{ brandId?: string }>();

  return (
    <Paper title={`${brandId ? 'Edit' : 'Create'} Brand`}>
      <BrandForm brandId={brandId} />
    </Paper>
  );
};

export { AdminEditBrand };
