import { ThemeOptions, createTheme } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1C4D96',
      light: '#607EC8',
      dark: '#062863'
    },
    secondary: {
      main: '#A0811D',
      light: '#D4AF37'
    }
  },
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeightLight: 400,
    fontSize: 15,
    h1: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 56
    },
    h2: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 40
    },
    h3: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 32,
      fontWeight: 700
    },
    h4: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 28,
      fontWeight: 700
    },
    h5: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 22
    },
    h6: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 18,
      fontWeight: 700
    },
    overline: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 1.1
    },
    subtitle1: {
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: 13,
      fontWeight: 700
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          display: 'flex',
          placeItems: 'center',
          backgroundColor: '#F5F5F5',
          width: '100%'
        },
        '#root': {
          width: '100%',
          heigth: '100%'
        },
        '::-webkit-scrollbar': {
          width: '8px'
        },
        '::-webkit-scrollbar-track': {
          background: '#F5F5F540'
        },
        '::-webkit-scrollbar-thumb': {
          background: '#777',
          borderRadius: '6px'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0
        }
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0
        }
      }
    }
  }
};

const theme = createTheme(themeOptions);

export { theme };
