import { Archive, Delete, Unarchive, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { characters } from '@/globals/constants';
import { Brand } from '@/globals/types';
import { Character } from '@/globals/types/Character';
import { useBrands, useCloudflare } from '@/hooks';
import { ConfirmationModal, EditBrandModal, Error, Loading, Paper } from '@/components/admin';
import { SearchInput, TableAlphabeticalIndex, TablePagination } from '@/components/admin/tables';

type SortByOptions = 'name' | 'url' | 'scraperPriority';

type SortDirection = 'asc' | 'desc';

const AdminListBrands = () => {
  const queryClient = useQueryClient();
  const { getImageUrl } = useCloudflare();
  const { fetchAllBrands, searchBrands, deleteBrand, editBrand } = useBrands();

  const [pages, setPages] = useState<number>(10);
  const [itemsCount, setItemsCount] = useState(0);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditBrandModalOpen, setIsEditBrandModalOpen] = useState(false);
  const [brandToArchive, setBrandToArchive] = useState<Brand>();
  const [brandToRemove, setBrandToRemove] = useState<Brand>();
  const [brandIdToUpdate, setBrandIdToUpdate] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: 'name',
    sortDirection: 'asc',
    page: '1',
    term: '',
    character: '',
    archived: ''
  });

  const {
    data: brands = [],
    isLoading,
    error: fetchAllBrandsError,
    refetch: refetchBrands
  } = useQuery({
    queryKey: [
      'fetchBrands',
      searchParams.get('sort'),
      searchParams.get('sortDirection'),
      searchParams.get('page'),
      searchParams.get('character'),
      searchParams.get('archived')
    ],
    queryFn: async () => {
      let response;
      const queryParams = {
        sortBy: searchParams.get('sortBy') || undefined,
        sortDirection: searchParams.get('sortDirection') || undefined,
        page: Number(searchParams.get('page')) || undefined,
        character: (searchParams.get('character') as Character) || undefined,
        archived: searchParams.get('archived') || undefined
      };
      if (searchParams.get('term')) {
        response = await searchBrands(searchParams.get('term') as string, queryParams);
      } else {
        response = await fetchAllBrands(queryParams);
      }
      setPages(Math.ceil(response.totalCount / 30));
      setItemsCount(response.totalCount);
      return response.brands;
    }
  });

  const { mutate: mutateDeleteBrand, error: deleteBrandError } = useMutation({
    mutationFn: (brandId: string) => deleteBrand(brandId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchBrands'] });
      setIsDeleteModalOpen(false);
      setBrandToRemove(undefined);
    }
  });

  const { mutate: mutateBrand, error: mutateBrandError } = useMutation({
    mutationFn: (brand: Partial<Brand> & { _id: string }) => editBrand(brand),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchBrands'] });
      setIsArchiveModalOpen(false);
      setBrandToArchive(undefined);
    }
  });

  const truncateUrl = (url: string) => {
    return url?.replace('https://', '').replace('http://', '');
  };

  const handleSort = (option: SortByOptions) => {
    setSearchParams({
      sortBy: option,
      sortDirection: searchParams.get('sortDirection') === 'asc' ? 'desc' : 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      term: searchParams.get('term') || '',
      archived: searchParams.get('archived') || ''
    });
  };

  const onSubmit = async (data: { term: string }) => {
    setSearchParams({
      sortBy: searchParams.get('sortBy') || 'name',
      sortDirection: searchParams.get('sortDirection') || 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      term: data.term || '',
      archived: searchParams.get('archived') || ''
    });
  };

  useEffect(() => {
    refetchBrands();
  }, [refetchBrands, searchParams]);

  return (
    <Container>
      <Paper title='Brands'>
        <Error error={fetchAllBrandsError || deleteBrandError || mutateBrandError} />
        <Box textAlign='center'>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={() => setIsEditBrandModalOpen(true)}
          >
            Create
          </Button>
        </Box>
        <SearchInput
          defaultValue={searchParams.get('term') as string}
          onSubmit={onSubmit}
        />
        <Chip
          label='View Archived'
          color='primary'
          variant={searchParams.get('archived') ? 'filled' : 'outlined'}
          onClick={() =>
            setSearchParams({
              sortBy: searchParams.get('sortBy') || 'name',
              sortDirection: searchParams.get('sortDirection') || 'asc',
              page: '1',
              character: searchParams.get('character') || '',
              term: searchParams.get('term') || '',
              archived: searchParams.get('archived') ? '' : 'true'
            })
          }
        />
        <Box>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <TableAlphabeticalIndex
                    colSpan={7}
                    character={(searchParams.get('character') as Character) || undefined}
                    onChange={(characterIndex) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: '1',
                        term: searchParams.get('term') || '',
                        archived: searchParams.get('archived') || '',
                        character:
                          characters[characterIndex - 1] === searchParams.get('character')
                            ? ''
                            : characters[characterIndex - 1]
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <Typography
                    variant='body2'
                    textAlign='center'
                    pb={2}
                  >
                    {itemsCount} items
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'name'}
                    direction={
                      searchParams.get('sortBy') === 'name'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : undefined
                    }
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'url'}
                    direction={
                      searchParams.get('sortBy') === 'url'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('url')}
                  >
                    Url
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'scraperPriority'}
                    direction={
                      searchParams.get('sortBy') === 'scraperPriority'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('scraperPriority')}
                  >
                    Priority
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Logo</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Hero</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Scraper</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loading isLoading={isLoading} />
                  </TableCell>
                </TableRow>
              ) : null}
              {brands.map(({ _id, name, slug, url, scraperPriority, heroImage, logo, scraperStatus, isArchived }) => (
                <TableRow key={_id}>
                  <TableCell>
                    <Link
                      underline='none'
                      onClick={() => {
                        setBrandIdToUpdate(_id);
                        setIsEditBrandModalOpen(true);
                      }}
                    >
                      {name}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    <Link
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      {truncateUrl(url || '')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    <Typography>{scraperPriority}</Typography>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    {logo ? (
                      <Link
                        href={getImageUrl(logo, '400x400')}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline='none'
                      >
                        <Visibility color='action' />
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    {heroImage ? (
                      <Link
                        href={getImageUrl(heroImage, '2000x614')}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline='none'
                      >
                        <Visibility color='action' />
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 60 }}>
                    <Typography>{scraperStatus}</Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={isArchived ? 'Restore' : 'Archive'}>
                      <IconButton
                        color={isArchived ? 'info' : 'primary'}
                        onClick={() => {
                          setIsArchiveModalOpen(true);
                          setBrandToArchive({ _id, name, slug, isArchived });
                        }}
                      >
                        {isArchived ? <Unarchive /> : <Archive />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                          setBrandToRemove({ _id, name, slug });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <TablePagination
                    pages={pages}
                    page={Number(searchParams.get('page'))}
                    onChange={(page) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: page.toString(),
                        term: searchParams.get('term') || '',
                        character: searchParams.get('character') || '',
                        archived: searchParams.get('archived') || ''
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </Paper>
      <EditBrandModal
        isOpen={isEditBrandModalOpen}
        brandId={brandIdToUpdate}
        onSuccess={() => {
          refetchBrands();
          setBrandIdToUpdate(undefined);
          setIsEditBrandModalOpen(false);
        }}
        onCancel={() => {
          setBrandIdToUpdate(undefined);
          setIsEditBrandModalOpen(false);
        }}
      />
      <ConfirmationModal
        key='archive-confirmation-modal'
        isOpen={isArchiveModalOpen}
        text={`Do you want to ${brandToArchive?.isArchived ? 'restore' : 'archive'} ${brandToArchive?.name}?`}
        confirmAction={() => mutateBrand({ _id: String(brandToArchive?._id), isArchived: !brandToArchive?.isArchived })}
        cancelAction={() => {
          setIsArchiveModalOpen(false);
          setBrandToArchive(undefined);
        }}
      />
      <ConfirmationModal
        key='delete-confirmation-modal'
        isOpen={isDeleteModalOpen}
        text={`Do you want to remove ${brandToRemove?.name}?`}
        confirmAction={() => mutateDeleteBrand(brandToRemove?._id as string)}
        cancelAction={() => {
          setIsDeleteModalOpen(false);
          setBrandToRemove(undefined);
        }}
      />
    </Container>
  );
};

export { AdminListBrands };
