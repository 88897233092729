import { Box, Button, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SweepstakesModal = ({ isOpen, setIsOpen }: Props) => {
  const [isTermsAndConditionsModalOpened, setIsTermsAndConditionsModalOpened] = useState(false);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth='md'
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box
        paddingX={{ xs: 4, md: 7 }}
        paddingTop={{ xs: 4, md: 7 }}
      >
        <Typography variant='h4'>Welcome to Audiophile Directory</Typography>
        <Typography>
          <p>
            Audiophile Directory is here to serve the entire HiFi community, acting as a central directory and search
            engine for everyone in the industry—from enthusiasts and newcomers to brands, dealers, and content creators.
            Our mission is not to compete but to complement the existing ecosystem by providing a starting point where
            all audio-related searches can begin. We direct users seamlessly to review sites, brand websites, dealer
            pages, YouTube channels, and forums, creating a unified platform that supports every stakeholder in the
            industry.
          </p>
          <p>
            Think of us as the “Zillow for the audio industry.” Audiophile Directory brings together scattered resources
            into a single, intuitive platform that makes it easier for people to find—and be found. Whether users are
            researching the best DACs, looking for a nearby dealer, or watching in-depth product reviews, Audiophile
            Directory simplifies the process by connecting them to the sources they need. By streamlining the search for
            HiFi information, we amplify visibility for brands, dealers, reviewers, and content creators alike.
          </p>
          <p>
            We believe that by serving as a central hub, we can elevate the entire audio community, making it easier for
            everyone to share their passion for high-quality sound.
          </p>
          <p>
            As we celebrate our launch, we’re thrilled to invite you to participate in our Sweepstakes!{' '}
            <b>One lucky winner will receive a $5,000 giftcard</b> to use at their local HiFi store. Read on to find out
            how to enter and take your listening experience to the next level
          </p>
        </Typography>
      </Box>
      <Box
        padding={0}
        width='100%'
        display='flex'
        justifyContent='center'
      >
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => setIsOpen(false)}
          href='https://www.jotform.com/form/242146733475055'
          target='_blank'
          rel='noopener noreferrer'
        >
          Participate
        </Button>
      </Box>
      <Button
        variant='text'
        sx={{ color: 'primary.light', margin: 2 }}
        onClick={() => setIsTermsAndConditionsModalOpened(true)}
      >
        Terms & Conditions
      </Button>
      <TermsAndConditionsModal
        isOpen={isTermsAndConditionsModalOpened}
        setIsOpen={setIsTermsAndConditionsModalOpened}
      />
    </Dialog>
  );
};

export { SweepstakesModal };
