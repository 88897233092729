import { Box, ButtonBase, Container, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCloudflare, useScreen } from '@/hooks';
import { ViewImageModal } from '@/components/admin';
import { Footer } from '@/components/shared';
import { FollowUsVertical, SweepstakesModal, TermsAndConditionsModal } from '@/components/socials';

const Home = () => {
  const { isDesktop, isMobile } = useScreen();
  const { getImageUrl } = useCloudflare();
  const [isViewingImage, setIsViewingImage] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState<string>();
  const [isTermsAndConditionsModalOpened, setIsTermsAndConditionsModalOpened] = useState(false);
  const [isSweepstakesModalOpened, setIsSweepstakesModalOpened] = useState(false);

  const bulletPoints = [
    'Your go-to hub for discovering high-quality audiophile products and brands',
    'In-depth product pages featuring links to all online reviews, YouTube videos, and forum posts',
    'Find authorized dealers by proximity, both online and local',
    'Expertly curated for audio enthusiasts who demand precision and depth',
    'Like Zillow for apartment hunters, we’re the dedicated resource Google just can’t match for audiophiles'
  ];

  const screenshots = [
    'f1a56368-951e-4150-8d2e-7be1162ffb00',
    'c99b0a83-08d2-41da-2840-6c197ac63b00',
    '0535924d-f88c-4e0d-7e55-b8706bb35b00',
    '06100015-dd9c-499f-0772-4baf7b216e00',
    'b3d197d0-5ce5-4bab-4571-c36e80d5ba00'
  ];

  return (
    <>
      <Helmet>
        <title>Audiophile Directory - Everything, One Place, All At Once</title>
        <meta charSet='UTF-8' />
        <meta
          name='keywords'
          content='HiFi audio, High-fidelity sound, Audiophile equipment, Audio reviews, Sound system components, Stereo equipment, Home audio, Audio forums, HiFi brands, Audio dealers, Speaker systems, Amplifiers, Turntables, Digital-to-analog converters (DACs), Preamps, Power amplifiers, Integrated amplifiers, Phono stages, Headphones, Earphones, Audio cables, Acoustic treatments, Vinyl records, Digital audio players, Streaming devices, Audio accessories, HiFi news, Audio shows and exhibitions, Product comparisons, Audio technology, Sound quality, Audiophile community, Music listening experience, Audio measurements, Frequency response, Soundstage and imaging, Dynamic range, Audio formats, Lossless audio, Hi-res audio, Room acoustics, Audio setup guides, Budget HiFi, High-end audio, Vintage audio equipment, Audio restoration, Audio calibration, Audio industry trends, New product releases, Audio enthusiast discussions'
        />
        <meta
          name='author'
          content='Ignacio Flores'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        />
        <meta
          name='description'
          content='Discover premium audiophile products, links to in-depth reviews, and authorized dealers. Audiophile Directory is your one-stop resource for all things high-fidelity audio.'
        />
        <meta
          property='og:title'
          content='Audiophile Directory'
        />
        <meta
          property='og:description'
          content='Your one-stop resource for high-quality audio products and reviews.'
        />
        <meta
          property='og:image'
          content='https://imagedelivery.net/cs6UzkOCmUTUZ4WmmAiVWg/8b1cfff8-e38d-44c2-6022-c492d2f21000/1200x1200'
        />
        <meta
          property='og:url'
          content='https://audiophiledirectory.com'
        />
        <meta
          name='twitter:card'
          content='summary_large_image'
        />
      </Helmet>
      <Container>
        <Box
          id='head'
          height={isMobile ? 1600 : 1380}
          justifyContent='center'
          flexDirection='column'
          display='flex'
          alignItems='center'
          sx={{
            backgroundImage: `linear-gradient(to bottom, #000000C0 0%, #00000000 500px),
            linear-gradient(to top, #000000C0 0%, #00000000 300px),
            url(${getImageUrl('198c060e-2762-4583-c90d-ee1331573800', isMobile ? '1200x1200' : '2000x2000')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center -420px'
          }}
        >
          <FollowUsVertical />
          <Typography
            variant='overline'
            color='white'
            fontSize={20}
            letterSpacing={4}
          >
            Coming Soon
          </Typography>
          <Box
            width={161}
            height={177}
            role='img'
            aria-label='Audiophile Directory Logo'
            sx={{
              background: 'url(assets/logo-centered-negative.svg) no-repeat',
              backgroundSize: 'cover'
            }}
          />
          <Typography
            variant='h1'
            color='white'
            fontFamily='"Source Sans Pro", sans-serif'
            fontSize={{ xs: 35, sm: 45, md: 63, lg: 68 }}
            fontWeight={400}
            textAlign={{ xs: 'center', md: 'left' }}
            pt={5}
            pb={2}
          >
            Everything, One Place, {isMobile ? '' : '\u00A0\u00A0\u00A0\u00A0'}
            <br />
            <span style={{ float: isMobile ? undefined : 'right' }}>All At Once</span>
          </Typography>
          <List
            role='list'
            aria-label='Key features of Audiophile Directory'
            sx={{
              background: `rgba(0, 0, 0, 0.2)`,
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              paddingX: '50px'
            }}
          >
            {bulletPoints.map((text, index) => (
              <ListItem
                key={index}
                disableGutters
                role='listitem'
                aria-label={text}
              >
                <ListItemText
                  primary={
                    <Box
                      display='flex'
                      alignItems='center'
                    >
                      <Box
                        alignItems='center'
                        width={20}
                        height={25}
                        sx={{
                          background: 'url(assets/list-item.svg) no-repeat',
                          backgroundSize: 'contain'
                        }}
                      />
                      <Typography
                        variant='h2'
                        color='white'
                        fontSize={22}
                        lineHeight={0.9}
                        ml={1}
                      >
                        {text}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              background: `rgba(255, 255, 255, 0.2)`,
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 30px rgba(255, 255, 255, 0.1)',
              width: '100%',
              paddingX: 4
            }}
          >
            <Typography
              variant='h2'
              fontSize={25}
              color='white'
              lineHeight={0.9}
              paddingY={4}
              textAlign='center'
            >
              Take a sneak peek of what's coming
            </Typography>
            <Swiper
              slidesPerView={isDesktop ? 3 : isMobile ? 1 : 2}
              navigation
              a11y={{
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide',
                firstSlideMessage: 'This is the first slide',
                lastSlideMessage: 'This is the last slide',
                paginationBulletMessage: 'Go to slide {{index}}'
              }}
              autoplay={{ delay: 5000 }}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {screenshots.map((item, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingBottom: 20
                  }}
                >
                  <img
                    srcSet={getImageUrl(item, '600x600')}
                    src={getImageUrl(item, '600x600')}
                    alt={`Application screenshot showing interface preview ${index + 1}`}
                    width={250}
                    height={510}
                    loading='lazy'
                    role='button'
                    tabIndex={0}
                    aria-label={`View larger version of screenshot ${index + 1}`}
                    onClick={() => {
                      setIsViewingImage(true);
                      setSelectedScreenshot(item);
                    }}
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'top'
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
        <Footer />
        <ViewImageModal
          isOpen={isViewingImage}
          setIsViewingImage={setIsViewingImage}
          imageId={selectedScreenshot as string}
          size='4000x4000'
          onKeyDown={(e) => {
            if (e.key === 'Escape') return setIsViewingImage(false);
            if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
              return setSelectedScreenshot((prev) => {
                let index = screenshots.findIndex((screenshot) => screenshot === prev);
                return e.key === 'ArrowRight'
                  ? screenshots[screenshots.length > index + 1 ? index + 1 : 0]
                  : screenshots[index - 1 < 0 ? screenshots.length - 1 : index - 1];
              });
            }
          }}
        />
        <Link
          key='get-listed-badge'
          href='https://www.jotform.com/form/242146545705052'
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Get listed in our directory - Opens in new tab'
          position='absolute'
          right={0}
          top={{ xs: 15, md: 74 }}
        >
          <Box
            width={{ xs: 189, md: 236 }}
            height={{ xs: 46, md: 57 }}
            sx={{
              background: 'url(assets/get-listed-badge.svg) no-repeat',
              backgroundSize: 'cover'
            }}
          />
        </Link>
        <ButtonBase
          key='sweepstakes-badge'
          onClick={() => setIsSweepstakesModalOpened(true)}
          aria-label='Open sweepstakes information'
          role='button'
          sx={{
            position: 'absolute',
            left: 0,
            top: { xs: 173, md: 240 }
          }}
        >
          <Box
            width={{ xs: 145, md: 193 }}
            height={{ xs: 57, md: 81 }}
            sx={{
              background: 'url(assets/sweepstakes-badge.svg) no-repeat',
              backgroundSize: 'cover'
            }}
          />
        </ButtonBase>
        <TermsAndConditionsModal
          isOpen={isTermsAndConditionsModalOpened}
          setIsOpen={setIsTermsAndConditionsModalOpened}
        />
        <SweepstakesModal
          isOpen={isSweepstakesModalOpened}
          setIsOpen={setIsSweepstakesModalOpened}
        />
      </Container>
    </>
  );
};

export { Home };
