import { useMediaQuery, useTheme } from '@mui/material';

const useScreen = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = !isMobile && !isDesktop;
  return { isDesktop, isMobile, isTablet };
};

export { useScreen };
