import { Box, Container, Dialog } from '@mui/material';
import { useCloudflare, useScreen } from '@/hooks';
import { CloudflareImageVariant } from '@/hooks/useCloudflare';

type Props = {
  isOpen: boolean;
  setIsViewingImage: (value: React.SetStateAction<boolean>) => void;
  imageId: string;
  size?: CloudflareImageVariant;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
};

const ViewImageModal = ({ isOpen, setIsViewingImage, imageId, size, onKeyDown }: Props) => {
  const { getImageUrl } = useCloudflare();
  const { isMobile } = useScreen();

  size ||= isMobile ? '600x600' : '1200x1200'

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsViewingImage(false)}
      maxWidth='xl'
      onKeyDown={onKeyDown}
    >
      <Container>
        <Box
          width={{ xs: 600, sm: 1200 }}
          sx={{
            backgroundColor: 'darkgray',
            backgroundImage: `linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0),
          linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0)`,
            backgroundSize: '40px 40px',
            backgroundPosition: '0 0, 20px 20px',
            lineHeight: 0
          }}
        >
          <img
            src={getImageUrl(imageId, size)}
            alt='image'
            style={{
              height: '100%',
              width: '100%',
              maxWidth: size.split('x')[0] + 'px',
              maxHeight: size.split('x')[1] + 'px',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Container>
    </Dialog>
  );
};

export { ViewImageModal };
