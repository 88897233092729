import { Add, LocationOn, Remove } from '@mui/icons-material';
import { Autocomplete, Box, Button, Grid2, TextField, Typography, debounce } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { Key, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Location, Point } from '@/globals/types';

type MainTextMatchedSubstrings = {
  offset: number;
  length: number;
};

type StructuredFormatting = {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
};

type PlaceType = {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
};

type Props = {
  index: number;
  location?: Pick<Location, 'address' | 'state' | 'city' | 'zipCode'>;
  error: boolean;
  helperText?: string;
  showAddLocation?: boolean;
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
};

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const LocationFields = ({ index, location, error, helperText, showAddLocation, setLocations }: Props) => {
  const [isAddLocationDisabled, setIsAddLocationDisabled] = useState<boolean>();
  const [address, setAddress] = useState<PlaceType | null>(null);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  const loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) return;
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetails = (placeId: string) => {
    if (!placesService.current) return;
    (placesService.current as any).getDetails({ placeId }, (place: any, status: any) => {
      if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
        const addressComp = place.address_components;
        const coordinates = [place.geometry.location.lat() as number, place.geometry.location.lng() as number];
        const address =
          addressComp.find(({ types }) => types.includes('route')).long_name +
          ' ' +
          addressComp.find(({ types }) => types.includes('street_number')).long_name;
        const city = addressComp.find(({ types }) => types.includes('locality')).long_name;
        const state = addressComp.find(({ types }) => types.includes('administrative_area_level_1')).long_name;
        const zipCode = addressComp.find(({ types }) => types.includes('postal_code')).long_name;
        setLocations((prev) =>
          prev.map((l, i) =>
            i === index ? { ...l, location: { type: 'Point', coordinates } as Point, address, city, state, zipCode } : l
          )
        );
      }
    });
  };

  useEffect(() => {
    let active = true;
    if (!options.length && location) {
      setAddress({
        place_id: '',
        description: location.address,
        structured_formatting: { main_text: location.address, secondary_text: '' }
      });
    }
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!placesService.current && (window as any).google) {
      placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
    }
    if (!autocompleteService.current) return undefined;
    if (location?.address === '') {
      setOptions(address ? [address] : []);
      return undefined;
    }
    fetch({ input: location?.address }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];
        if (address) {
          newOptions = [address];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
      setOptions([]);
    };
  }, [location?.address]);

  useEffect(() => {
    setIsAddLocationDisabled(!address?.description);
  }, [address]);

  return (
    <Box
      width={'100%'}
      sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px' }}
    >
      <Grid2
        container
        spacing={2}
        size={{ sm: 12 }}
      >
        <Grid2 size={{ sm: 12 }}>
          <Typography
            variant='h6'
            gutterBottom
          >
            Location {index + 1}:
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12 }}>
          <Autocomplete
            id={`locations.${index}.address`}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={address}
            noOptionsText='No locations'
            onChange={(_e: SyntheticEvent, newValue: PlaceType | null) => {
              const address = newValue?.structured_formatting.main_text;
              if (address) {
                setAddress(newValue);
                if (newValue?.place_id) {
                  fetchPlaceDetails(newValue.place_id);
                }
              }
            }}
            onInputChange={(_e, value) =>
              setLocations((prev) => prev.map((l, i) => (i === index ? { ...l, address: value } : l)))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label='Address'
                variant='outlined'
                fullWidth
                error={error}
                helperText={helperText}
                value={location?.address}
              />
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const matches = option.structured_formatting.main_text_matched_substrings || [];
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [match.offset, match.offset + match.length])
              );
              return (
                <li
                  key={key}
                  {...optionProps}
                >
                  <Grid2
                    container
                    sx={{ alignItems: 'center' }}
                  >
                    <Grid2 sx={{ display: 'flex', width: 44 }}>
                      <LocationOn sx={{ color: 'text.secondary' }} />
                    </Grid2>
                    <Grid2 sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      {parts.map((part: { highlight: any; text: string }, index: Key) => (
                        <Box
                          key={index}
                          component='span'
                          sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography
                        variant='body2'
                        sx={{ color: 'text.secondary' }}
                      >
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </li>
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            id={`locations.${index}.state`}
            type='text'
            label='State'
            variant='outlined'
            fullWidth
            error={error}
            helperText={helperText}
            value={location?.state}
            onChange={(e) => {
              setLocations((prev) => prev.map((l, i) => (i === index ? { ...l, state: e.target.value } : l)));
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            id={`locations.${index}.city`}
            type='text'
            label='City'
            variant='outlined'
            fullWidth
            error={error}
            helperText={helperText}
            value={location?.city}
            onChange={(e) => {
              setLocations((prev) => prev.map((l, i) => (i === index ? { ...l, city: e.target.value } : l)));
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            id={`locations.${index}.zipCode`}
            type='text'
            label='Zip Code'
            variant='outlined'
            fullWidth
            error={error}
            helperText={helperText}
            value={location?.zipCode}
            onChange={(e) => {
              setLocations((prev) => prev.map((l, i) => (i === index ? { ...l, zipCode: e.target.value } : l)));
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={{ sm: 12 }}>
        <Box
          flex={1}
          alignContent='center'
          display='space-between'
          justifyContent='center'
          pt={2}
        >
          {showAddLocation ? (
            <Button
              key={`addLocation.${index}`}
              sx={{ height: '100%', marginRight: 1 }}
              component='label'
              variant='contained'
              disabled={isAddLocationDisabled}
              color={'primary'}
              startIcon={<Add />}
              onClick={() => setLocations((prev) => prev.concat({ address: '', state: '', city: '', zipCode: '' }))}
            >
              Add Location
            </Button>
          ) : null}
          <Button
            key={`removeLocation.${index}`}
            sx={{ height: '100%', marginLeft: 1 }}
            component='label'
            variant='contained'
            color={'error'}
            startIcon={<Remove />}
            onClick={() => setLocations((prev) => prev.filter((_, i) => i !== index))}
          >
            Remove Location
          </Button>
        </Box>
      </Grid2>
    </Box>
  );
};

export { LocationFields };
