import { useParams } from 'react-router-dom';
import { DistributorForm, Paper } from '@/components/admin';

const AdminEditDistributor = () => {
  const { distributorId } = useParams<{ distributorId?: string }>();

  return (
    <Paper title={`${distributorId ? 'Edit' : 'Create'} Distributor`}>
      <DistributorForm distributorId={distributorId} />
    </Paper>
  );
};

export { AdminEditDistributor };
