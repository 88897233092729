import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { countries, socialNetworks } from '@/globals/constants';
import { Brand, Country, Distributor, Location, Retailer } from '@/globals/types';
import { useBrands, useCloudflare, useDistributors, useRetailers } from '@/hooks';
import { distributorSchema } from '@/pages/admin/distributors/schemas';
import {
  DeleteImageButton,
  EditBrandModal,
  EditImageButton,
  EditRetailerModal,
  Error,
  Field,
  FieldAutocomplete,
  Form,
  FormActions,
  Loading,
  LocationFields,
  ViewImageButton
} from '.';

type Props = {
  distributorId?: string;
  onSuccess?: (distributor: Distributor) => void;
  onCancel?: () => void;
};

const DistributorForm = ({ distributorId, onSuccess, onCancel }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createDistributor, editDistributor, fetchDistributor, fetchDistributorBySlug } = useDistributors();
  const { fetchAllBrands } = useBrands();
  const { fetchAllRetailers } = useRetailers();
  const { uploadImage, deleteImage } = useCloudflare();

  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [isEditBrandModalOpen, setIsEditBrandModalOpen] = useState(false);
  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([]);
  const [isEditRetailerModalOpen, setIsEditRetailerModalOpen] = useState(false);
  const [isEditOnlineStoreModalOpen, setIsEditOnlineStoreModalOpen] = useState(false);
  const [selectedOnlineStores, setSelectedOnlineStores] = useState<Retailer[]>([]);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [logo, setLogo] = useState<File | null>();
  const [heroImage, setHeroImage] = useState<File | null>();
  const [locations, setLocations] = useState<Location[]>([{ address: '', state: '', city: '', zipCode: '' }]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm<Distributor>({ resolver: zodResolver(distributorSchema) });

  const {
    data: distributor,
    isLoading: isFetchingDistributor,
    error: fetchDistributorError
  } = useQuery({
    queryKey: ['fetchDistributor', distributorId],
    enabled: !!distributorId,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const distributor = await fetchDistributor(distributorId as string);
      if (distributor.locations) setLocations(distributor.locations)
      return distributor;
    }
  });

  const {
    data: brands,
    isLoading: isFetchingAllBrands,
    error: fetchAllBrandsError
  } = useQuery({
    queryKey: ['fetchAllBrands'],
    enabled: !distributorId || !!distributor,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allBrands = (await fetchAllBrands()).brands.map(({ _id, name, slug }) => ({
        _id,
        name,
        slug
      }));
      setSelectedBrands((prev) =>
        allBrands.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (distributor?.brands as string[]) || []).includes(_id)
        )
      );
      return allBrands.concat({ _id: '', name: 'Other', slug: '' });
    }
  });

  const {
    data: retailers,
    isLoading: isFetchingAllRetailers,
    error: fetchAllRetailersError
  } = useQuery({
    queryKey: ['fetchAllRetailers'],
    enabled: !distributorId || !!distributor,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const allRetailers = (await fetchAllRetailers()).retailers.map(({ _id, name, slug }) => ({ _id, name, slug }));
      setSelectedRetailers((prev) =>
        allRetailers.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (distributor?.retailers as string[]) || []).includes(_id)
        )
      );
      setSelectedOnlineStores((prev) =>
        allRetailers.filter(({ _id }) =>
          (prev.length ? prev.map(({ _id }) => _id) : (distributor?.onlineStores as string[]) || []).includes(_id)
        )
      );
      return allRetailers.concat({ _id: '', name: 'Other', slug: '' });
    }
  });

  const {
    isPending: isMutatingDistributor,
    mutate: mutateDistributor,
    error: mutateDistributorError
  } = useMutation({
    mutationFn: async (changes: Distributor) => {
      if (heroImage) {
        if (distributor?.heroImage) await deleteImage(distributor.heroImage);
        const heroImageId = await uploadImage(heroImage, { distributor: changes.slug, type: 'hero' });
        if (heroImageId) changes.heroImage = heroImageId;
      }
      if (heroImage === null && distributor?.heroImage) {
        await deleteImage(distributor.heroImage);
        changes.heroImage = '';
      }
      if (logo) {
        if (distributor?.logo) await deleteImage(distributor.logo);
        const logoImageId = await uploadImage(logo, { distributor: changes.slug, type: 'logo' });
        if (logoImageId) changes.logo = logoImageId;
      }
      if (logo === null && distributor?.logo) {
        await deleteImage(distributor.logo);
        changes.logo = '';
      }
      changes.url &&= changes.url.replace(/^https?:\/\/www\./, 'https://').replace(/\/$/, '');
      return distributorId
        ? editDistributor({
            ...changes,
            _id: distributorId,
            locations: locations.filter((location) => location.address)
          })
        : createDistributor({ ...changes, locations: locations.filter((location) => location.address) });
    },
    onSuccess: (data) => {
      if (distributorId) queryClient.invalidateQueries({ exact: true, queryKey: ['fetchDistributor', distributorId] });
      queryClient.invalidateQueries({ exact: true, queryKey: ['fetchAllDistributors'] });
      if (onSuccess) {
        onSuccess(data);
      } else {
        navigate(-1);
      }
    }
  });

  const handleUrlChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value.trim().toLowerCase();
      const hasProtocol = ['https://', 'http://'].some((pref) => text.startsWith(pref));
      setValue('url', hasProtocol ? text : (`https://${text}` as string), { shouldDirty: true });
    },
    [setValue]
  );

  const isLoading = useMemo(
    () => isFetchingDistributor || isMutatingDistributor || isFetchingAllBrands || isFetchingAllRetailers,
    [isFetchingDistributor, isMutatingDistributor, isFetchingAllBrands, isFetchingAllRetailers]
  );

  const error = useMemo(
    () => fetchDistributorError || fetchAllBrandsError || fetchAllRetailersError || mutateDistributorError,
    [fetchDistributorError, fetchAllBrandsError, fetchAllRetailersError, mutateDistributorError]
  );

  const onSubmit = (data: Distributor) => mutateDistributor(data);

  useEffect(() => {
    setIsSlugValid(!!distributor?.slug);
  }, [distributor?.slug]);

  useEffect(() => {
    setValue(
      'brands',
      selectedBrands.map((brand) => brand._id),
      { shouldDirty: true }
    );
  }, [selectedBrands, setValue]);

  useEffect(() => {
    setValue(
      'retailers',
      selectedRetailers.map((retailer) => retailer._id),
      { shouldDirty: true }
    );
  }, [selectedRetailers, setValue]);

  useEffect(() => {
    setValue(
      'onlineStores',
      selectedOnlineStores.map((store) => store._id),
      { shouldDirty: true }
    );
  }, [selectedOnlineStores, setValue]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Error error={error} />
      {!isLoading && !error ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            id='name'
            control={control}
            defaultValue={distributor?.name}
            error={!!errors.name}
            helperText={errors.name?.message}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
              setValue('name', e.target.value);
            }}
          />
          <Field
            id='slug'
            control={control}
            defaultValue={distributor?.slug}
            onChange={(e) => {
              setValue('slug', e.target.value.trim().split(' ').join('-').toLowerCase());
              if (isSlugValid !== !!e.target.value.trim().length) setIsSlugValid(!!e.target.value.trim().length);
            }}
            onBlur={(e) =>
              fetchDistributorBySlug(e.target.value).then(({ _id }) => {
                if (!_id || _id === distributorId) {
                  setIsSlugValid(true);
                  clearErrors('slug');
                } else {
                  setIsSlugValid(false);
                  setError('slug', { type: 'unique', message: 'Slug already exists' }, { shouldFocus: true });
                }
              })
            }
            error={!!errors.slug}
            helperText={errors.slug?.message}
          />
          <Field
            id='url'
            type='url'
            sm={4}
            control={control}
            defaultValue={distributor?.url}
            error={!!errors.url}
            helperText={errors.url?.message}
            onChange={handleUrlChanges}
          />
          <FieldAutocomplete
            id='isArchived'
            sm={2}
            control={control}
            options={['true', 'false']}
            defaultValue={distributor?.isArchived ? 'true' : 'false'}
            error={!!errors.isArchived}
            helperText={errors.isArchived?.message}
            onChange={(_, value) => setValue('isArchived', value === 'true')}
          />
          <Field
            id='popularity'
            type='number'
            sm={3}
            control={control}
            defaultValue={distributor?.popularity}
            error={!!errors.popularity}
            helperText={errors.popularity?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('popularity', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('popularity', (value > 5 ? 5 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5);
            }}
          />
          <Field
            id='scraperPriority'
            type='number'
            sm={3}
            control={control}
            defaultValue={distributor?.scraperPriority}
            error={!!errors.scraperPriority}
            helperText={errors.scraperPriority?.message}
            onChange={(e) => {
              if (!e.target.value) return setValue('scraperPriority', null);
              const value = Math.abs(Number(e.target.value) % 10);
              setValue('scraperPriority', (value > 6 ? 6 : value < 1 ? undefined : value) as 1 | 2 | 3 | 4 | 5 | 6);
            }}
          />
          <Field
            id='description'
            control={control}
            defaultValue={distributor?.description}
            sm={12}
            rows={6}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <ViewImageButton
            text='View Hero'
            imageId={distributor?.heroImage}
          />
          <EditImageButton
            key='Edit Hero'
            text='Hero'
            action={heroImage || distributor?.heroImage ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${distributor?.slug || 'distributor'}-hero.png`}
            setImage={setHeroImage}
          />
          <DeleteImageButton
            disabled={(!heroImage && !distributor?.heroImage) || heroImage === null}
            onDelete={() => setHeroImage(null)}
            text='Hero'
          />
          <ViewImageButton
            text='View Logo'
            imageId={distributor?.logo}
          />
          <EditImageButton
            key='Edit Logo'
            text='Logo'
            action={logo || distributor?.logo ? 'edit' : 'upload'}
            disabled={!isSlugValid}
            fileName={`${distributor?.slug || 'distributor'}-logo.png`}
            setImage={setLogo}
          />
          <DeleteImageButton
            disabled={(!logo && !distributor?.logo) || logo === null}
            onDelete={() => setLogo(null)}
            text='Logo'
          />
          <FieldAutocomplete
            id='country'
            sm={6}
            control={control}
            options={countries}
            defaultValue={distributor?.country}
            error={!!errors.country}
            helperText={errors.country?.message}
            onChange={(_, value) => setValue('country', value as Country)}
          />
          {locations.map((location, index) => (
            <LocationFields
              key={`location-${index}`}
              index={index}
              location={location}
              error={!!errors.locations}
              helperText={errors.locations?.message}
              showAddLocation={index === locations.length - 1}
              setLocations={setLocations}
            />
          ))}
          <Field
            id='email'
            type='email'
            control={control}
            defaultValue={distributor?.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Field
            id='phoneNumber'
            type='tel'
            control={control}
            defaultValue={distributor?.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
          {socialNetworks.map((socialNetwork) => (
            <Field
              id={socialNetwork}
              key={socialNetwork}
              label={socialNetwork.replace(/^./, (str) => str.toUpperCase())}
              control={control}
              defaultValue={distributor ? distributor[socialNetwork] : undefined}
              error={!!errors[socialNetwork]}
              helperText={errors[socialNetwork]?.message}
            />
          ))}
          <FieldAutocomplete
            id='brands'
            control={control}
            options={brands || []}
            value={selectedBrands}
            onChange={(_, value) => {
              if ((value as Brand[]).some(({ name }) => name === 'Other')) {
                return setIsEditBrandModalOpen(true);
              }
              const newSelectedBrands = (value as Brand[]).filter(({ name }) => name !== 'Other');
              setSelectedBrands(newSelectedBrands);
            }}
            getOptionLabel={(option) => (option as Brand).name}
            getOptionKey={(option) => (option as Brand).slug}
            multiple={true}
            error={!!errors.brands}
            helperText={errors.brands?.message}
          />
          <FieldAutocomplete
            id='onlineStores'
            sm={12}
            control={control}
            options={retailers || []}
            value={selectedOnlineStores}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsEditOnlineStoreModalOpen(true);
              }
              const newSelectedOnlineStores = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedOnlineStores(newSelectedOnlineStores);
            }}
            getOptionLabel={(option) => (option as Retailer).name}
            getOptionKey={(option) => (option as Retailer).slug}
            multiple={true}
            error={!!errors.onlineStores}
            helperText={errors.onlineStores?.message}
          />
          <FieldAutocomplete
            id='retailers'
            sm={12}
            control={control}
            options={retailers || []}
            value={selectedRetailers}
            onChange={(_, value) => {
              if ((value as Retailer[]).some(({ name }) => name === 'Other')) {
                return setIsEditRetailerModalOpen(true);
              }
              const newSelectedRetailers = (value as Retailer[]).filter(({ name }) => name !== 'Other');
              setSelectedRetailers(newSelectedRetailers);
            }}
            getOptionLabel={(option) => (option as Retailer).name}
            getOptionKey={(option) => (option as Retailer).slug}
            multiple={true}
            error={!!errors.retailers}
            helperText={errors.retailers?.message}
          />
          <Field
            id='notes'
            sm={12}
            rows={2}
            control={control}
            defaultValue={distributor?.notes}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />
          <FormActions
            saveAction={handleSubmit(onSubmit)}
            cancelAction={onCancel}
            isSaveButtonDisabled={!isDirty}
          />
        </Form>
      ) : null}
      <EditBrandModal
        isOpen={isEditBrandModalOpen}
        onSuccess={({ _id, name, slug }) => {
          setSelectedBrands((prev) => [...prev, { _id, name, slug }]);
          setIsEditBrandModalOpen(false);
        }}
        onCancel={() => setIsEditBrandModalOpen(false)}
      />
      <EditRetailerModal
        isOpen={isEditRetailerModalOpen || isEditOnlineStoreModalOpen}
        onSuccess={({ _id, name, slug }) => {
          setSelectedRetailers((prev) => [...prev, { _id, name, slug }]);
          setIsEditRetailerModalOpen(false);
          setIsEditOnlineStoreModalOpen(false);
        }}
        onCancel={() => {
          setIsEditRetailerModalOpen(false);
          setIsEditOnlineStoreModalOpen(false);
        }}
        isBrandOnlineStore={isEditOnlineStoreModalOpen}
      />
    </Container>
  );
};

export { DistributorForm };
