import { SocialNetwork } from '../types';

const socialNetworks: SocialNetwork[] = [
  'whatsApp',
  'instagram',
  'facebook',
  'youtube',
  'tikTok',
  'pinterest',
  'discord',
  'linkedIn',
  'x'
];

export { socialNetworks };
