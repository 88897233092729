import { Box, Dialog, Paper, Typography } from '@mui/material';
import { Brand } from '@/globals/types';
import { BrandForm } from '.';

type Props = {
  isOpen: boolean;
  brandId?: string;
  onSuccess: (brand: Brand) => void;
  onCancel: () => void;
};

const EditBrandModal = ({ isOpen, brandId, onSuccess, onCancel }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth={false}
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Paper>
        <Box padding={3}>
          <Typography
            variant='h4'
            gutterBottom
          >
            {brandId ? 'Edit' : 'Create'} Brand
          </Typography>
          <BrandForm
            brandId={brandId}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        </Box>
      </Paper>
    </Dialog>
  );
};

export { EditBrandModal };
