import { Grid2, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';

type Props<T extends FieldValues> = {
  id: Path<T>;
  label?: string;
  control: Control<T>;
  multiline?: boolean;
  rows?: number;
  defaultValue?: PathValue<T, Path<T>>;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 12;
  type?: React.HTMLInputTypeAttribute;
  error: boolean;
  helperText?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const Field = <T extends FieldValues>({
  id,
  label = id.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
  control,
  rows = 1,
  defaultValue = '' as PathValue<T, Path<T>>,
  sm = 6,
  type = 'text',
  error,
  helperText,
  onChange,
  onBlur
}: Props<T>) => (
  <Grid2 size={{ xs: 12, sm }}>
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue ?? undefined}
      render={({ field }) => (
        <TextField
          {...field}
          id={id}
          type={type}
          label={label}
          variant='outlined'
          rows={rows}
          multiline={rows > 1}
          fullWidth
          error={error}
          helperText={helperText}
          onChange={onChange ? onChange : field.onChange}
          onBlur={(e) => {
            if (type === 'string') {
              field.onChange(e.target.value.trim());
            }
            if (onBlur) {
              onBlur(e);
            }
          }}
        />
      )}
    />
  </Grid2>
);

export { Field };
