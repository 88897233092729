import { Archive, Delete, Unarchive, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { characters } from '@/globals/constants';
import { Retailer } from '@/globals/types';
import { Character } from '@/globals/types/Character';
import { useCloudflare, useRetailers } from '@/hooks';
import { ConfirmationModal, EditRetailerModal, Error, Loading, Paper } from '@/components/admin';
import { SearchInput, TableAlphabeticalIndex, TablePagination } from '@/components/admin/tables';

type SortByOptions = 'name' | 'url' | 'scraperPriority';

type SortDirection = 'asc' | 'desc';

const AdminListRetailers = () => {
  const queryClient = useQueryClient();
  const { getImageUrl } = useCloudflare();
  const { fetchAllRetailers, searchRetailers, deleteRetailer, editRetailer } = useRetailers();

  const [pages, setPages] = useState<number>(10);
  const [itemsCount, setItemsCount] = useState(0);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditRetailerModalOpen, setIsEditRetailerModalOpen] = useState(false);
  const [retailerToArchive, setRetailerToArchive] = useState<Retailer>();
  const [retailerToRemove, setRetailerToRemove] = useState<Retailer>();
  const [retailerIdToUpdate, setRetailerIdToUpdate] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: 'name',
    sortDirection: 'asc',
    page: '1',
    term: '',
    character: '',
    archived: ''
  });

  const {
    data: retailers = [],
    isLoading,
    error: fetchAllRetailersError,
    refetch: refetchRetailers
  } = useQuery({
    queryKey: [
      'fetchRetailers',
      searchParams.get('sort'),
      searchParams.get('sortDirection'),
      searchParams.get('page'),
      searchParams.get('character'),
      searchParams.get('archived')
    ],
    queryFn: async () => {
      let response;
      const queryParams = {
        sortBy: searchParams.get('sortBy') || undefined,
        sortDirection: searchParams.get('sortDirection') || undefined,
        page: Number(searchParams.get('page')) || undefined,
        character: (searchParams.get('character') as Character) || undefined,
        archived: searchParams.get('archived') || undefined
      };
      if (searchParams.get('term')) {
        response = await searchRetailers(searchParams.get('term') as string, queryParams);
      } else {
        response = await fetchAllRetailers(queryParams);
      }
      setPages(Math.ceil(response.totalCount / 30));
      setItemsCount(response.totalCount);
      return response.retailers;
    }
  });

  const { mutate: mutateDeleteRetailer, error: deleteRetailerError } = useMutation({
    mutationFn: (retailerId: string) => deleteRetailer(retailerId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRetailers'] });
      setIsDeleteModalOpen(false);
      setRetailerToRemove(undefined);
    }
  });

  const { mutate: mutateRetailer, error: mutateRetailerError } = useMutation({
    mutationFn: (retailer: Partial<Retailer> & { _id: string }) => editRetailer(retailer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRetailers'] });
      setIsArchiveModalOpen(false);
      setRetailerToArchive(undefined);
    }
  });

  const truncateUrl = (url: string) => {
    return url?.replace('https://', '').replace('http://', '');
  };

  const handleSort = (option: SortByOptions) => {
    setSearchParams({
      sortBy: option,
      sortDirection: searchParams.get('sortDirection') === 'asc' ? 'desc' : 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      archived: searchParams.get('archived') || '',
      term: searchParams.get('term') || ''
    });
  };

  const onSubmit = async (data: { term: string }) => {
    setSearchParams({
      sortBy: searchParams.get('sortBy') || 'name',
      sortDirection: searchParams.get('sortDirection') || 'asc',
      page: '1',
      character: searchParams.get('character') || '',
      archived: searchParams.get('archived') || '',
      term: data.term || ''
    });
  };

  useEffect(() => {
    refetchRetailers();
  }, [refetchRetailers, searchParams]);

  return (
    <Container>
      <Paper title='Retailers'>
        <Error error={fetchAllRetailersError || deleteRetailerError || mutateRetailerError} />
        <Box textAlign='center'>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={() => setIsEditRetailerModalOpen(true)}
          >
            Create
          </Button>
        </Box>
        <SearchInput
          defaultValue={searchParams.get('term') as string}
          onSubmit={onSubmit}
        />
        <Chip
          label='View Archived'
          color='primary'
          variant={searchParams.get('archived') ? 'filled' : 'outlined'}
          onClick={() =>
            setSearchParams({
              sortBy: searchParams.get('sortBy') || 'name',
              sortDirection: searchParams.get('sortDirection') || 'asc',
              page: '1',
              character: searchParams.get('character') || '',
              term: searchParams.get('term') || '',
              archived: searchParams.get('archived') ? '' : 'true'
            })
          }
        />
        <Box>
          <Table padding='checkbox'>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <TableAlphabeticalIndex
                    colSpan={7}
                    character={(searchParams.get('character') as Character) || undefined}
                    onChange={(characterIndex) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: '1',
                        term: searchParams.get('term') || '',
                        archived: searchParams.get('archived') || '',
                        character:
                          characters[characterIndex - 1] === searchParams.get('character')
                            ? ''
                            : characters[characterIndex - 1]
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <Typography
                    variant='body2'
                    textAlign='center'
                    pb={2}
                  >
                    {itemsCount} items
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'name'}
                    direction={
                      searchParams.get('sortBy') === 'name'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : undefined
                    }
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'url'}
                    direction={
                      searchParams.get('sortBy') === 'url'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('url')}
                  >
                    Url
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>
                  <TableSortLabel
                    active={searchParams.get('sortBy') === 'scraperPriority'}
                    direction={
                      searchParams.get('sortBy') === 'scraperPriority'
                        ? (searchParams.get('sortDirection') as SortDirection)
                        : 'asc'
                    }
                    onClick={() => handleSort('scraperPriority')}
                  >
                    Priority
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Logo</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Hero</TableCell>
                <TableCell sx={{ maxWidth: 60 }}>Scraper</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loading isLoading={isLoading} />
                  </TableCell>
                </TableRow>
              ) : null}
              {retailers.map(
                ({ _id, name, slug, url, scraperPriority, heroImage, logo, scraperStatus, isArchived }) => (
                  <TableRow key={_id}>
                    <TableCell>
                      <Link
                        underline='none'
                        onClick={() => {
                          setRetailerIdToUpdate(_id);
                          setIsEditRetailerModalOpen(true);
                        }}
                      >
                        {name}
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      <Link
                        href={url}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline='none'
                      >
                        {truncateUrl(url || '')}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ maxWidth: 60 }}>
                      <Typography>{scraperPriority}</Typography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: 60 }}>
                      {logo ? (
                        <Link
                          href={getImageUrl(logo, '400x400')}
                          target='_blank'
                          rel='noopener noreferrer'
                          underline='none'
                        >
                          <Visibility color='action' />
                        </Link>
                      ) : null}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 60 }}>
                      {heroImage ? (
                        <Link
                          href={getImageUrl(heroImage, '2000x614')}
                          target='_blank'
                          rel='noopener noreferrer'
                          underline='none'
                        >
                          <Visibility color='action' />
                        </Link>
                      ) : null}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 60 }}>
                      <Typography>{scraperStatus}</Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={isArchived ? 'Restore' : 'Archive'}>
                        <IconButton
                          color={isArchived ? 'info' : 'primary'}
                          onClick={() => {
                            setIsArchiveModalOpen(true);
                            setRetailerToArchive({ _id, name, slug, isArchived });
                          }}
                        >
                          {isArchived ? <Unarchive /> : <Archive />}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Delete'>
                        <IconButton
                          color='error'
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setRetailerToRemove({ _id, name, slug });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ border: 'none' }}
                >
                  <TablePagination
                    pages={pages}
                    page={Number(searchParams.get('page'))}
                    onChange={(page) => {
                      setSearchParams({
                        sortBy: searchParams.get('sortBy') || 'name',
                        sortDirection: searchParams.get('sortDirection') || 'asc',
                        page: page.toString(),
                        term: searchParams.get('term') || '',
                        archived: searchParams.get('archived') || '',
                        character: searchParams.get('character') || ''
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </Paper>
      <EditRetailerModal
        isOpen={isEditRetailerModalOpen}
        retailerId={retailerIdToUpdate}
        onSuccess={() => {
          refetchRetailers();
          setRetailerIdToUpdate(undefined);
          setIsEditRetailerModalOpen(false);
        }}
        onCancel={() => {
          setRetailerIdToUpdate(undefined);
          setIsEditRetailerModalOpen(false);
        }}
      />
      <ConfirmationModal
        key='archive-confirmation-modal'
        isOpen={isArchiveModalOpen}
        text={`Do you want to ${retailerToArchive?.isArchived ? 'restore' : 'archive'} ${retailerToArchive?.name}?`}
        confirmAction={() =>
          mutateRetailer({ _id: String(retailerToArchive?._id), isArchived: !retailerToArchive?.isArchived })
        }
        cancelAction={() => {
          setIsArchiveModalOpen(false);
          setRetailerToArchive(undefined);
        }}
      />
      <ConfirmationModal
        key='delete-confirmation-modal'
        isOpen={isDeleteModalOpen}
        text={`Do you want to remove ${retailerToRemove?.name}?`}
        confirmAction={() => mutateDeleteRetailer(retailerToRemove?._id as string)}
        cancelAction={() => {
          setIsDeleteModalOpen(false);
          setRetailerToRemove(undefined);
        }}
      />
    </Container>
  );
};

export { AdminListRetailers };
