import { Box, Button, Dialog, Typography } from '@mui/material';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TermsAndConditionsModal = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth='md'
      sx={{ overflowY: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box
        paddingX={{ xs: 4, md: 7 }}
        paddingTop={{ xs: 4, md: 7 }}
      >
        <Typography variant='h4'>Terms and Conditions for the Audiophile Directory Launch Sweepstakes</Typography>
        <Typography>
          <p>
            1. Agreement to Official Rules and Terms: By participating in the Audiophile Directory Launch Sweepstakes
            (the “Sweepstakes”), entrants agree to comply with and be bound by these Terms and Conditions, the Official
            Rules, and the decisions of Audiophile Directory, which are final in all respects. These Terms and
            Conditions apply only to the Sweepstakes and do not alter or replace any other terms, policies, or
            agreements governing your use of the Audiophile Directory website or other services.
          </p>
          <p>
            2. Sweepstakes Period: The Sweepstakes begins on [Start Date] at [Start Time] and ends on [End Date] at [End
            Time] (the “Entry Period”). Audiophile Directory’s computer is the official time-keeping device for the
            Sweepstakes.
          </p>
          <p>
            3. Eligibility: Entrants must be legal residents of the United States and 18 years or older at the time of
            entry. Employees, officers, and directors of Audiophile Directory and its affiliates, subsidiaries, and
            partners, and members of their immediate families or households, are not eligible to participate or win.
          </p>
          <p>
            4. How to Enter: Entrants must complete the entry form, including answering a brief questionnaire and
            submitting a valid email address. Additionally, entrants may earn bonus entries by referring friends through
            a unique referral link. Each friend who successfully enters using the referral link will count as one
            additional entry for the referrer. All entries must be received during the Entry Period to be eligible.
          </p>
          <p>
            5. Prize Details: One (1) winner will receive $5,000 to use at a HiFi store of their choice. The prize is
            non-transferable, non-exchangeable, and not redeemable for cash or other prizes.
          </p>
          <p>
            6. Winner Selection and Notification: The potential winner will be selected in a random drawing from all
            eligible entries received during the Entry Period. The drawing will take place on or around 01/31/2025. The
            potential winner will be notified via the email address provided at the time of entry within five (5) days
            of the drawing. The winner must respond within seven (7) days or an alternate winner may be chosen.
          </p>
          <p>
            7. Privacy and Use of Personal Information: By participating in the Sweepstakes, entrants consent to the
            collection, use, and disclosure of their personal information by Audiophile Directory solely for the
            purposes of administering the Sweepstakes, notifying the winner, and for marketing purposes in accordance
            with Audiophile Directory's Privacy Policy. Entrants may unsubscribe from promotional emails at any time
            without impacting their chances of winning.
          </p>
          <p>
            8. Publicity Release: By accepting the prize, the winner agrees to allow Audiophile Directory to use their
            name, likeness, and entry for promotional and advertising purposes without additional compensation, except
            where prohibited by law.
          </p>
          <p>
            9. General Conditions: Audiophile Directory reserves the right to modify, suspend, or terminate the
            Sweepstakes if any fraud, technical failures, or other factors beyond Audiophile Directory’s control impair
            the integrity of the Sweepstakes, as determined by Audiophile Directory in its sole discretion. Audiophile
            Directory also reserves the right to disqualify any entrant who tampers with the entry process or violates
            these Terms and Conditions.
          </p>
          <p>
            10. Release and Limitation of Liability: By participating in the Sweepstakes, entrants agree to release and
            hold harmless Audiophile Directory, its affiliates, subsidiaries, officers, directors, and employees from
            and against any claims or causes of action arising from participation in the Sweepstakes or receipt or use
            of any prize, including, but not limited to, personal injury, property damage, or claims based on publicity
            rights, defamation, or invasion of privacy.{' '}
          </p>
          <p>
            11. Disputes: All disputes arising from or relating to the Sweepstakes shall be resolved in the courts of
            the State of Delaware, without resort to any form of class action. These Terms and Conditions, the Official
            Rules, and any disputes will be governed by the laws of the State of Delaware. 12. Sponsor: This Sweepstakes
            is sponsored by Audiophile Directory.
          </p>
        </Typography>
      </Box>
      <Box
        padding={5}
        width='100%'
        display='flex'
        justifyContent='center'
      >
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => setIsOpen(false)}
          sx={{ minWidth: 300 }}
        >
          Ok, understood
        </Button>
      </Box>
    </Dialog>
  );
};

export { TermsAndConditionsModal };
