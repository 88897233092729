import { Box, Button, Container, Dialog, Grid2, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CanvasPreview } from './components';

type Props = {
  isOpen: boolean;
  aspect?: number;
  image?: File;
  fileName: string;
  cancelAction: () => void;
  confirmAction: (cropedImage: File) => void;
};

const CropImages = ({ isOpen = false, aspect, image, fileName, cancelAction, confirmAction }: Props) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(image);
    }
  }, [image]);

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onConfirm = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    const blob = await offscreen.convertToBlob({ type: 'image/png', quality: 1 });
    confirmAction(new File([blob], fileName, { type: blob.type }));
  };

  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  useEffect(() => {
    const t = setTimeout(() => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    }, 100);
    return () => clearTimeout(t);
  }, [completedCrop, scale, rotate]);

  return (
    <Dialog open={isOpen}>
      <Container>
        <Box padding={5}>
          <Grid2
            container
            spacing={2}
            pb={2}
          >
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <TextField
                label='Scale'
                type='number'
                fullWidth
                value={scale}
                disabled={!imgSrc}
                slotProps={{ htmlInput: { step: 0.1 } }}
                onChange={(e) => setScale(Number(e.target.value))}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <TextField
                label='Rotate'
                type='number'
                fullWidth
                value={rotate}
                disabled={!imgSrc}
                slotProps={{ htmlInput: { step: 0.1 } }}
                onChange={(e) => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
              />
            </Grid2>
          </Grid2>
          {!!imgSrc && (
            <Box
              sx={{
                backgroundColor: 'darkgray',
                backgroundImage:
                  'linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0),' +
                  'linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0)',
                backgroundSize: '40px 40px',
                backgroundPosition: '0 0, 20px 20px',
                lineHeight: 0,
                margin: '0 auto'
              }}
              width='fit-content'
              textAlign='center'
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt='Crop me'
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: '250px', minWidth: '250px' }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Box>
          )}
          {!!completedCrop && (
            <Container>
              <Box
                marginTop={2}
                textAlign='center'
              >
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    objectFit: 'contain',
                    maxHeight: '250px',
                    maxWidth: '100%',
                    backgroundColor: 'darkgray',
                    backgroundImage:
                      'linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0),' +
                      'linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0)',
                    backgroundSize: '40px 40px',
                    backgroundPosition: '0 0, 20px 20px'
                  }}
                />
              </Box>
              <Box pt={2}>
                <Grid2
                  container
                  spacing={2}
                  justifyContent='center'
                >
                  <Grid2
                    size={{ xs: 12, sm: 6 }}
                    textAlign='center'
                  >
                    <Button
                      variant='contained'
                      fullWidth
                      onClick={onConfirm}
                    >
                      Confirm
                    </Button>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6 }}
                    textAlign='center'
                  >
                    <Button
                      variant='contained'
                      color='error'
                      fullWidth
                      onClick={cancelAction}
                    >
                      Cancel
                    </Button>
                  </Grid2>
                </Grid2>
              </Box>
            </Container>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export { CropImages };
